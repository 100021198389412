import {
  GET_COMPANY_FAIL,
  GET_COMPANY_LIST_FAIL,
  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
} from 'redux/actionType'

const initState = {
  companyList: [],
  companyListLoading: false,
  companyListMeta: {},
  company: {},
  companyLoading: false,
}

const companyReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_COMPANY_LIST_REQUEST: {
      return {
        ...state,
        companyListLoading: true,
      }
    }
    case GET_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        companyListLoading: false,
        companyList: action.data?.objects,
        companyListMeta: action.data?.meta,
      }
    }
    case GET_COMPANY_LIST_FAIL: {
      return {
        ...state,
        companyListLoading: false,
      }
    }
    case GET_COMPANY_REQUEST: {
      return {
        ...state,
        company: {},
        companyLoading: true,
      }
    }
    case GET_COMPANY_SUCCESS: {
      return {
        ...state,
        company: action.data,
        companyLoading: false,
      }
    }
    case GET_COMPANY_FAIL: {
      return {
        ...state,
        companyLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default companyReducer
