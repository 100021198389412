import PublicLayout from 'layout/PublicLayout/PublicLayout'
import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'

const PublicRoute = (props) => {
  const {component, ...rest} = props

  useEffect(() => {
    document.getElementsByTagName('html').scrollTop = 0
  }, [])

  return (
    <PublicLayout>
      <Route {...rest} component={component} />
    </PublicLayout>
  )
}

export default PublicRoute
