import SearchMultiselectInput from 'component/field/SearchMultiselectInput'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Trans} from '@lingui/macro'

const AddFleetDialog = (props) => {
  const {open, onClose, onSubmit, fleetList, supplierFleets, fleetListLoading} = props

  const [selectedFleets, setSelectedFleets] = useState([])

  const handleChangeFleets = (options) => {
    // find all selected fleet information, check if they are already in fleet table
    // and add create action
    const filteredFleets = options?.map(({id}) => {
      if (!supplierFleets.find((s) => s.fleet.id === id)) {
        return {fleet: {...fleetList.find((f) => f.id === id)}, action: 'create'}
      }
    })
    setSelectedFleets(filteredFleets)
  }

  const submitFleets = () => {
    // filter out undefined (duplicity) fleets
    onSubmit(selectedFleets.filter((f) => !!f))
  }

  return (
    <DialogWindow
      title={<Trans>Add fleet</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      dialogActions={
        <GridContainer spacing={3} justifyContent={'flex-end'}>
          <GridItem>
            <PrimaryButton
              onClick={onClose}
              variant={'outlined'}
              size={'small'}
              fullWidth={false}
              text={<Trans>Close</Trans>}
            />
          </GridItem>
          <GridItem>
            <PrimaryButton
              type="submit"
              size={'small'}
              onClick={submitFleets}
              fullWidth={false}
              text={<Trans>Add</Trans>}
            />
          </GridItem>
        </GridContainer>
      }
    >
      <GridContainer spacing={4} direction={'row'}>
        <GridItem xs={12}>
          <SearchMultiselectInput
            options={fleetList}
            onChange={(event, options) => handleChangeFleets(options)}
            loading={fleetListLoading}
            label={<Trans>Fleets</Trans>}
          />
        </GridItem>
      </GridContainer>
    </DialogWindow>
  )
}

AddFleetDialog.propTypes = {
  fleetList: PropTypes.array,
  fleetListMeta: PropTypes.object,
  fleetListLoading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default compose(
  connect((store) => {
    return {
      fleetList: store.fleet.fleetList,
      fleetListMeta: store.fleet.fleetListMeta,
      fleetListLoading: store.fleet.fleetListLoading,
    }
  }, mapDispatchToProps)
)(AddFleetDialog)
