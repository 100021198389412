import {DATE_FORMAT} from 'helper/constants'
import moment from 'moment'
import React from 'react'
import {Trans} from '@lingui/macro'

/// undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators =
  (...validators) =>
  (value) => {
    return validators.reduce((error, validator) => error || validator(value), undefined)
  }

export const required = (value) => {
  return value ? undefined : <Trans>Required field</Trans>
}

export const validateEmail = (value) => {
  if (value && !value?.includes('@')) {
    return <Trans>Wrong email address</Trans>
  }
  return undefined
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.new_password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}

export const maxDate = (maxDate) => (value) => {
  if (moment(maxDate, DATE_FORMAT) < moment(value, DATE_FORMAT)) {
    return (
      <Trans>Date cannot be greater than {moment(maxDate, DATE_FORMAT).format(DATE_FORMAT)}</Trans>
    )
  }
  return undefined
}

export const minDate = (minDate) => (value) => {
  if (moment(minDate, DATE_FORMAT) > moment(value, DATE_FORMAT)) {
    return (
      <Trans>Date cannot be smaller than {moment(minDate, DATE_FORMAT).format(DATE_FORMAT)}</Trans>
    )
  }
  return undefined
}

export const validateVin = (value) => {
  if (value && value?.length !== 17) {
    return <Trans>VIN must be 17 characters long</Trans>
  } else if (value && (value.includes('I') || value.includes('O') || value.includes('Q'))) {
    return <Trans>Value is not valid VIN</Trans>
  }
  return undefined
}

export const validatePower = (value) => {
  if (value && value < 10 && parseInt(value, 10) !== 0) {
    return <Trans>Power must be between 10 and 1000, or 0 if not applicable</Trans>
  }
  return undefined
}

export const validateVehicleCC = (value) => {
  if (value && value < 500 && parseInt(value, 10) !== 0) {
    return <Trans>Vehicle cc must be between 500 and 9999, or 0 if not applicable</Trans>
  }
  return undefined
}
