import {GET_COUNTRY_FAIL, GET_COUNTRY_REQUEST, GET_COUNTRY_SUCCESS} from 'redux/actionType'

const initState = {
  countryList: [],
  countryListLoading: false,
  countryListMeta: {},
}

const countryReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_COUNTRY_REQUEST: {
      return {
        ...state,
        countryListLoading: true,
      }
    }
    case GET_COUNTRY_SUCCESS: {
      return {
        ...state,
        countryListLoading: false,
        countryList: action.data?.objects,
        countryListMeta: action.data?.meta,
      }
    }
    case GET_COUNTRY_FAIL: {
      return {
        ...state,
        countryListLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default countryReducer
