import {
  GET_FLEET_FAIL,
  GET_FLEET_LIST_FAIL,
  GET_FLEET_LIST_REQUEST,
  GET_FLEET_LIST_SUCCESS,
  GET_FLEET_MAP_FAIL,
  GET_FLEET_MAP_REQUEST,
  GET_FLEET_MAP_SUCCESS,
  GET_FLEET_REQUEST,
  GET_FLEET_SUCCESS,
} from 'redux/actionType'

const initState = {
  fleetList: [],
  fleetListLoading: false,
  fleetListMeta: {},
  fleet: {},
  fleetLoading: false,
  fleetMap: {},
  fleetMapLoading: false,
}

const fleetReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_FLEET_LIST_REQUEST: {
      return {
        ...state,
        fleetListLoading: true,
      }
    }
    case GET_FLEET_LIST_SUCCESS: {
      return {
        ...state,
        fleetListLoading: false,
        fleetList: action.data?.objects,
        fleetListMeta: action.data?.meta,
      }
    }
    case GET_FLEET_LIST_FAIL: {
      return {
        ...state,
        fleetListLoading: false,
      }
    }
    case GET_FLEET_REQUEST: {
      return {
        ...state,
        fleet: {},
        fleetLoading: true,
      }
    }
    case GET_FLEET_SUCCESS: {
      return {
        ...state,
        fleet: action.data,
        fleetLoading: false,
      }
    }
    case GET_FLEET_FAIL: {
      return {
        ...state,
        fleetLoading: false,
      }
    }
    case GET_FLEET_MAP_REQUEST: {
      return {
        ...state,
        fleetMapLoading: true,
      }
    }
    case GET_FLEET_MAP_SUCCESS: {
      return {
        ...state,
        fleetMap: action.data,
        fleetMapLoading: false,
      }
    }
    case GET_FLEET_MAP_FAIL: {
      return {
        ...state,
        fleetMapLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default fleetReducer
