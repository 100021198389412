const toastStyle = (theme) => ({
  containerStyle: {
    '& .Toastify__toast--success': {
      backgroundColor: theme.palette.success.main,
    },
    '& .Toastify__toast--warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '& .Toastify__toast--info': {
      backgroundColor: theme.palette.info.main,
    },
    '& .Toastify__toast--error': {
      backgroundColor: theme.palette.error.main,
    },
    '& .Toastify__toast': {
      height: 'auto',
      minHeight: '48px',
      borderRadius: theme.shape.borderRadius,
      padding: '12px 15px',
      fontSize: '12px',
    },
    '& .Toastify__toast-container': {
      minWidth: '400px',
    },
  },
  toast: {
    display: 'flex',
    alignItems: 'center',
  },
  toastIcon: {
    marginRight: '10px',
  },
})
export default toastStyle
