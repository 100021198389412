import {
  GET_PROVIDER_FAIL,
  GET_PROVIDER_LIST_FAIL,
  GET_PROVIDER_LIST_REQUEST,
  GET_PROVIDER_LIST_SUCCESS,
  GET_PROVIDER_REQUEST,
  GET_PROVIDER_SUCCESS,
} from 'redux/actionType'

const initState = {
  providerList: [],
  providerListLoading: false,
  providerListMeta: {},
  provider: {},
  providerLoading: false,
}

const providerReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_PROVIDER_LIST_REQUEST: {
      return {
        ...state,
        providerListLoading: true,
      }
    }
    case GET_PROVIDER_LIST_SUCCESS: {
      return {
        ...state,
        providerListLoading: false,
        providerList: action.data?.objects,
        providerListMeta: action.data?.meta,
      }
    }
    case GET_PROVIDER_LIST_FAIL: {
      return {
        ...state,
        providerListLoading: false,
      }
    }
    case GET_PROVIDER_REQUEST: {
      return {
        ...state,
        provider: {},
        providerLoading: true,
      }
    }
    case GET_PROVIDER_SUCCESS: {
      return {
        ...state,
        provider: action.data,
        providerLoading: false,
      }
    }
    case GET_PROVIDER_FAIL: {
      return {
        ...state,
        providerLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default providerReducer
