import {LS_GLOBAL_SETTINGS} from 'helper/configConstants'
import {getItemFromStorage, setItemToStorage} from 'helper/functions'
import {UPDATE_GLOBAL_SETTINGS_SUCCESS} from 'redux/actionType'

const initState = getItemFromStorage(LS_GLOBAL_SETTINGS, {
  rowsPerPage: 20,
  rowsPerPageOptions: [20, 50, 100],
  currentLanguage: 'en',
})

const globalSettingsReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_GLOBAL_SETTINGS_SUCCESS) {
    const settings = {
      ...state,
      ...action.data,
    }

    setItemToStorage(LS_GLOBAL_SETTINGS, settings)
    return settings
  }
  return state
}

export default globalSettingsReducer
