import PasswordInput from 'component/field/PasswordInput'
import fieldStyle from 'component/field/fieldStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PaperFlexGrow from 'component/material/PaperFlexGrow'
import PrimaryButton from 'component/material/PrimaryButton'
import {redirectTo} from 'helper/functions'
import {required, samePasswordValidation} from 'helper/validations'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {resetPassword} from 'redux/action/authAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

const ResetPasswordPage = (props) => {
  const {classes, resetPassword, resetLoading} = props
  let {token} = useParams()

  const [submitting, setSubmitting] = useState(false)

  const handleResetPassword = (values) => {
    setSubmitting(true)
    return resetPassword(token, {password: values.new_password})
      .then((res) => {
        redirectTo('/login')
      })
      .catch(() => {})
      .finally(() => setSubmitting(false))
  }

  return (
    <GridContainer direction="row" justifyContent="center" alignItems="center">
      <GridItem xs={12} sm={7} md={5} lg={4} xl={3} style={{zIndex: '1'}}>
        <PaperFlexGrow elevation={5}>
          <GridContainer direction="column" justifyContent="center" alignItems="center">
            <BoxFullWidth px={18} py={10}>
              <BoxFullWidth pb={7}>
                <Typography variant="h4" color="primary" align="center" gutterBottom>
                  <Trans>Reset password</Trans>
                </Typography>
              </BoxFullWidth>

              <Form onSubmit={handleResetPassword} validate={samePasswordValidation}>
                {(formProps) => {
                  return (
                    <form onSubmit={formProps.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Field
                            name="new_password"
                            label={<Trans>Password</Trans>}
                            component={PasswordInput}
                            loginPage={true}
                            validate={required}
                            disabled={formProps.submitting}
                            className={classes.loginPageInput}
                            filters={true}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <Field
                            name="password_confirmation"
                            label={<Trans>Password again</Trans>}
                            component={PasswordInput}
                            loginPage={true}
                            validate={required}
                            disabled={formProps.submitting}
                            className={classes.loginPageInput}
                            filters={true}
                          />
                        </GridItem>
                        <GridItem container justifyContent={'flex-end'}>
                          <Box pt={5}>
                            <PrimaryButton
                              fullWidth={false}
                              type="submit"
                              text={<Trans>Reset</Trans>}
                              disabled={formProps.submitting}
                              className={classes.loginButton}
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )
                }}
              </Form>
            </BoxFullWidth>
          </GridContainer>
        </PaperFlexGrow>
      </GridItem>
    </GridContainer>
  )
}

ResetPasswordPage.propTypes = {
  classes: PropTypes.object,
  resetPassword: PropTypes.func,
  resetLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPassword,
    },
    dispatch
  )
}

export default compose(
  withStyles(fieldStyle),
  connect((store) => {
    return {
      resetLoading: store.auth.loading,
    }
  }, mapDispatchToProps)
)(ResetPasswordPage)
