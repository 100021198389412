import DatePickerInput from 'component/field/DatePickerInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {DB_DATE_FORMAT} from 'helper/constants'
import {fileToBase64, mapErrorResponseToForm} from 'helper/functions'
import {required} from 'helper/validations'
import moment from 'moment'
import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {postSupContracts} from 'redux/action/suppliersAction'
import {Trans} from '@lingui/macro'

const NewContractDialog = (props) => {
  const {classes, id, open, onClose, postSupContracts} = props

  const [fileData, setFileData] = useState({})

  const handleSubmit = (values) => {
    return postSupContracts(id, {
      signed_at: moment(values.signed_at).format(DB_DATE_FORMAT),
      file: fileData,
    })
      .then(() => {
        onClose()
      })
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  const handleUploadFile = async (e) => {
    if (e.target.files[0]) {
      const base64String = await fileToBase64(e.target.files[0])
      const fileName = e.target.files[0].name
      const base64Parsed = base64String.split('base64,')[1]
      setFileData({
        filename: fileName,
        base64_content: base64Parsed,
      })
    }
  }

  return (
    <DialogWindow
      title={<Trans>New general contract</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
    >
      <Form onSubmit={handleSubmit}>
        {({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
              <GridItem container xs={12}>
                <Field
                  disabled={submitting}
                  name="signed_at"
                  label={<Trans>Signed at</Trans>}
                  component={DatePickerInput}
                  placeholder={'dd.mm.yyyy'}
                  disableFuture={true}
                  inLineInput={false}
                  validate={required}
                />
              </GridItem>

              <GridItem container xs={12}>
                <Field
                  disabled={submitting}
                  name="file"
                  label={<Trans>PDF contract</Trans>}
                  component={TextInput}
                  type="file"
                  className={classes.cursorPointer}
                  onChange={handleUploadFile}
                  inputProps={{accept: ['application/pdf']}}
                  validate={required}
                />
              </GridItem>

              <BoxFullWidth pt={4} align={'right'}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  size="small"
                  type="submit"
                  text={<Trans>Send</Trans>}
                />
              </BoxFullWidth>
            </GridContainer>
          </form>
        )}
      </Form>
    </DialogWindow>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postSupContracts,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(NewContractDialog)
