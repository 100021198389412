import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_PROVIDER_FAIL,
  GET_PROVIDER_LIST_FAIL,
  GET_PROVIDER_LIST_REQUEST,
  GET_PROVIDER_LIST_SUCCESS,
  GET_PROVIDER_REQUEST,
  GET_PROVIDER_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getProviderList = (limit = 1000, offset = 0, meta) => {
  return (dispatch) => {
    dispatch({type: GET_PROVIDER_LIST_REQUEST})
    return httpClient
      .get(endpoints.providers, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_PROVIDER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PROVIDER_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getProvider = (id) => {
  return (dispatch) => {
    dispatch({type: GET_PROVIDER_REQUEST})
    return httpClient
      .get(endpoints.providers + '/' + id)
      .then((res) => {
        dispatch({type: GET_PROVIDER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PROVIDER_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createProvider = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.providers, data)
      .then((res) => {
        fireSuccessToast(<Trans>New provider created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteProvider = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.providers + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Provider deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        return globalApiErrorHandler(err)
      })
  }
}

export const patchProvider = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.providers + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Provider updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
