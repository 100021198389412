import componentStyle from 'component/componentStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getStatusList} from 'redux/action/statusesAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

let searchInterval = null

const StatusesPage = (props) => {
  const {classes, rowsPerPage, getStatusList, statusList, statusListLoading, statusListMeta} = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'description', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [searchValue, setSearchValue] = useState(null)

  const openDetail = (row) => () => {
    redirectTo('/status/' + row.id)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'description',
      sortKey: 'description',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'description',
      sortKey: 'description',
      label: <Trans>Description</Trans>,
    },
    {
      name: 'enabled',
      sortKey: 'enabled',
      label: <Trans>Enabled</Trans>,
      render: (val) => (val ? '' : <Trans>disabled</Trans>),
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/status/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      handleChangePage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getStatusList(rowsPerPage, page * rowsPerPage, tableSort, searchValue).catch(() => {})
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={statusListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div>
          <CustomTooltip title={<Trans>New status</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Add</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>
      <div className={classes.overFlowAuto}>
        <Table
          data={statusList}
          columns={columns}
          loading={statusListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          meta={statusListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

StatusesPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getStatusList: PropTypes.func,
  statusList: PropTypes.array,
  statusListLoading: PropTypes.bool,
  statusListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStatusList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      statusList: store.status.statusList,
      statusListLoading: store.status.statusListLoading,
      statusListMeta: store.status.statusListMeta,
    }
  }, mapDispatchToProps)
)(StatusesPage)
