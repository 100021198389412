import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import {DATE_FORMAT} from 'helper/constants'
import PropTypes from 'prop-types'
import React from 'react'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import {DatePicker} from '@material-ui/pickers'

// KeyboardDatePicker API https://material-ui-pickers.dev/api/KeyboardDatePicker
function DatePickerInput(props) {
  const {
    classes,
    helperText,
    input,
    meta,
    label,
    inputVariant = 'outlined',
    showHelperText = true,
    clear,
    clearable,
    disableFuture = false,
    onChange,
    disabled,
    filters,
    inLineInput,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div className={cx(classes.fullWidth, !filters && classes.inLineField)}>
      {label && (
        <div
          className={cx(
            classes.fieldLabel,
            filters && classes.filtersFieldLabel,
            inLineInput && classes.inLineFieldLabel
          )}
        >
          {label}
        </div>
      )}

      <div
        className={cx(
          classes.helperText,
          classes.datePickerInput,
          classes.datePickerEndAdornmentPadding,
          classes.inputStyles,
          disabled && classes.disabledDatePicker
        )}
      >
        <DatePicker
          {...input}
          {...rest}
          value={input?.value ? input.value : null}
          inputVariant={inputVariant}
          disabled={disabled}
          autoOk={true}
          format={DATE_FORMAT}
          error={!!errorMessage}
          helperText={errorHelperText}
          invalidDateMessage={<Trans>Invalid Date Format</Trans>}
          maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
          minDateMessage={<Trans>Date should not be before minimal date</Trans>}
          cancelLabel={<Trans>Close</Trans>}
          clearLabel={<Trans>Reset</Trans>}
          okLabel={<Trans>Ok</Trans>}
          disableFuture={disableFuture}
          clearable={clearable}
          onChange={(e) => {
            input?.onChange(e)
            onChange && onChange({target: {value: e, name: input?.name}})
          }}
        />
      </div>
    </div>
  )
}

DatePickerInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  invalidDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  minDateMessage: PropTypes.node,
  cancelLabel: PropTypes.node,
  okLabel: PropTypes.node,
}

export default withStyles(fieldStyle)(DatePickerInput)
