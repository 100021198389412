import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {GET_COUNTRY_FAIL, GET_COUNTRY_REQUEST, GET_COUNTRY_SUCCESS} from 'redux/actionType'

export const getCountryList = (limit = 1000, offset = 0, meta) => {
  return (dispatch) => {
    dispatch({type: GET_COUNTRY_REQUEST})
    return httpClient
      .get(endpoints.countries, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_COUNTRY_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_COUNTRY_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}
