import ContractAccordion from 'component/CustomersPage/ContractAccordion'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {diff} from 'deep-object-diff'
import {mapErrorResponseToForm} from 'helper/functions'
import {required} from 'helper/validations'
import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  getCustomerDefaultAdjustmentTemplates,
  patchCustomerContractAdjustmentTemplates,
  postCustomerContractAdjustmentTemplates,
} from 'redux/action/customersAction'
import {Trans} from '@lingui/macro'

const ContractTemplateDialog = (props) => {
  const {
    id,
    open,
    data,
    onClose,
    onSuccess,
    postCustomerContractAdjustmentTemplates,
    getCustomerDefaultAdjustmentTemplates,
    customerDefaultTemplate,
    customerDefaultTemplateLoading,
    patchCustomerContractAdjustmentTemplates,
  } = props

  const handleCreateNewTemplate = (values) => {
    if (data?.id) {
      const patchData = diff(createInitialValues(), values)
      patchCustomerContractAdjustmentTemplates(id, data.id, patchData)
        .then(() => {
          onClose()
          onSuccess()
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      const postData = diff(customerDefaultTemplate, values)
      postCustomerContractAdjustmentTemplates(id, postData)
        .then(() => {
          onClose()
          onSuccess()
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  useEffect(() => {
    if (open) {
      getCustomerDefaultAdjustmentTemplates(id).catch(() => {})
    }
  }, [open])

  const createInitialValues = () => {
    const values = {...customerDefaultTemplate}

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (value) values[key] = value
      }
    }

    return values
  }

  return (
    <DialogWindow
      title={
        data?.id ? (
          <Trans>New contract adjustment template</Trans>
        ) : (
          <Trans>Edit contract adjustment template</Trans>
        )
      }
      open={open}
      onClose={onClose}
      maxWidth={'md'}
    >
      <Form onSubmit={handleCreateNewTemplate} initialValues={createInitialValues()}>
        {({submitting, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
              <GridItem xs={6}>
                <Field
                  disabled={submitting}
                  name="name"
                  label={<Trans>Template name</Trans>}
                  component={TextInput}
                  filters={true}
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>1. Preamble</Trans>}
                  defaultExpanded={true}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_1"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                      helperText={
                        'Placeholder {{ customer }} must stay in text unchanged. During contract generation will be substituted for real value.'
                      }
                    />
                  }
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>2. Order processing</Trans>}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_2"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                    />
                  }
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>3. Spare parts use</Trans>}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_3"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                    />
                  }
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>4. Service inspections</Trans>}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_4"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                    />
                  }
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>5. Insurance claims settlement</Trans>}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_5"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                    />
                  }
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>6. Additional information</Trans>}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_6"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                    />
                  }
                />
              </GridItem>
              <GridItem container xs={12}>
                <ContractAccordion
                  title={
                    <Trans>
                      7. Terms and conditions for invoicing of service operations and services
                    </Trans>
                  }
                  content={
                    <>
                      <GridItem xs={12}>
                        <Field
                          disabled={submitting}
                          name="section_7a"
                          component={TextInput}
                          multiline
                          minRows={4}
                          filters={true}
                        />
                      </GridItem>
                      <GridItem container xs={12} justifyContent={'center'}>
                        <table id={'example'}>
                          <thead>
                            <tr>
                              <th>
                                <Trans>Work type</Trans>
                              </th>
                              <th>
                                <Trans>Unit</Trans>
                              </th>
                              <th>
                                <Trans>Price per unit</Trans>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {[1, 2, 3, 4].map((n) => (
                              <tr key={n}>
                                <td align={'left'}>
                                  <Trans>Work type {n}</Trans>
                                </td>
                                <td align={'center'}>{<Trans>hour</Trans>}</td>
                                <td align={'center'}>
                                  <Trans>{n * 139},-</Trans>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          disabled={submitting}
                          name="section_7b"
                          component={TextInput}
                          multiline
                          minRows={4}
                          filters={true}
                        />
                      </GridItem>
                    </>
                  }
                />
              </GridItem>
              <GridItem xs={12}>
                <ContractAccordion
                  title={<Trans>8. Invoicing</Trans>}
                  content={
                    <Field
                      disabled={submitting}
                      name="section_8"
                      component={TextInput}
                      multiline
                      minRows={4}
                      filters={true}
                    />
                  }
                />
              </GridItem>
              {customerDefaultTemplate.section_9 && (
                <GridItem xs={12}>
                  <ContractAccordion
                    title={<Trans>9. Return bonus for LKQ</Trans>}
                    content={
                      <Field
                        disabled={submitting}
                        name="section_9"
                        component={TextInput}
                        multiline
                        minRows={4}
                        filters={true}
                        helperText={
                          'Placeholder {{ bonus }} must stay in text unchanged. During contract generation will be substituted for real value.'
                        }
                      />
                    }
                  />
                </GridItem>
              )}
              <BoxFullWidth pt={4} align={'right'}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  textPadding={5}
                  size="small"
                  type="submit"
                  text={<Trans>Save</Trans>}
                />
              </BoxFullWidth>
            </GridContainer>
          </form>
        )}
      </Form>
    </DialogWindow>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerDefaultAdjustmentTemplates,
      postCustomerContractAdjustmentTemplates,
      patchCustomerContractAdjustmentTemplates,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      customerDefaultTemplate: store.customer.customerDefaultTemplate,
      customerDefaultTemplateLoading: store.customer.customerDefaultTemplateLoading,
    }
  }, mapDispatchToProps)
)(ContractTemplateDialog)
