import cx from 'classnames'
import componentStyle from 'component/componentStyle'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {fileToBase64, mapErrorResponseToForm} from 'helper/functions'
import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {postBulkImport} from 'redux/action/cfsAction'
import buttonStyle from 'style/components/buttonStyle'
import {Trans} from '@lingui/macro'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const BulkImportPage = (props) => {
  const {classes, postBulkImport} = props

  const [fileData, setFileData] = useState({})

  const handleSubmit = (values) => {
    return postBulkImport({kind: values.kind, base64_content: fileData.base64_content}).catch(
      (err) => {
        return mapErrorResponseToForm(err)
      }
    )
  }

  const handleUploadFile = async (e) => {
    if (e.target.files[0]) {
      const base64String = await fileToBase64(e.target.files[0])
      const base64Parsed = base64String.split('base64,')[1]
      setFileData({
        base64_content: base64Parsed,
      })
    }
  }

  return (
    <Accordion
      className={cx(classes.tableFilters, classes.tabsCursorDefault)}
      elevation={0}
      expanded={true}
      defaultExpanded={true}
    >
      <AccordionSummary expandIcon={null}>
        <BoxFullWidth className={classes.formTitle}>
          <Trans>Import</Trans>
        </BoxFullWidth>
      </AccordionSummary>
      <AccordionDetails>
        <Form onSubmit={handleSubmit}>
          {({handleSubmit, submitting}) => (
            <form onSubmit={handleSubmit}>
              <GridContainer
                spacing={4}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <GridItem container xs={12} sm={6} md={4} lg={3}>
                  <Field
                    disabled={submitting}
                    name="kind"
                    label={<Trans>Type</Trans>}
                    component={SelectInput}
                    emptyOption={true}
                    showHelperText={true}
                    filters={true}
                    options={[
                      {value: 'FLEET', label: <Trans>Fleets</Trans>},
                      {value: 'FLEET_MEMBERSHIP', label: <Trans>Linking fleets to services</Trans>},
                      {value: 'CAR', label: <Trans>Cars</Trans>},
                      {value: 'TERM', label: <Trans>Terms</Trans>},
                    ]}
                  />
                </GridItem>

                <GridItem container xs={12} sm={6} md={4} lg={3}>
                  <Field
                    showHelperText={true}
                    filters={true}
                    disabled={submitting}
                    name="base64_content"
                    label={<Trans>CSV file</Trans>}
                    component={TextInput}
                    type="file"
                    className={classes.cursorPointer}
                    onChange={handleUploadFile}
                    inputProps={{accept: ['.csv']}}
                  />
                </GridItem>

                <GridItem container xs={12} sm={6} md={4} lg={3}>
                  <Box pt={4}>
                    <PrimaryButton
                      fullWidth={false}
                      disabled={submitting}
                      size="small"
                      type="submit"
                      text={<Trans>Send</Trans>}
                    />
                  </Box>
                </GridItem>
              </GridContainer>
            </form>
          )}
        </Form>
      </AccordionDetails>
    </Accordion>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({postBulkImport}, dispatch)
}

export default compose(
  withStyles((theme) => ({
    ...componentStyle(theme),
    ...buttonStyle(theme),
  })),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(BulkImportPage)
