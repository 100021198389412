const componentStyle = (theme) => ({
  overFlowAuto: {
    overflow: 'auto',
  },
  listPageControlContainer: {
    background: theme.palette.background.elitGray,
    border: '1px solid ' + theme.palette.border,
    boxSizing: 'border-box',
    height: 50,
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    marginBottom: 20,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchLabel: {
    paddingRight: 10,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary + 'cc',
  },
  searchInput: {
    width: 240,
    boxSizing: 'border-box',
  },
  absoluteButtonUsers: {
    position: 'absolute',
    top: 84,
    right: 40,
  },
  circle: {
    display: 'inline-flex',
    borderRadius: '50%',
    width: 14,
    height: 14,
  },
  criticalCircle: {
    backgroundColor: theme.palette.error.main,
  },
  criticalText: {
    color: theme.palette.error.main,
  },
  warningCircle: {
    backgroundColor: theme.palette.warning.main,
  },
  warningText: {
    color: theme.palette.warning.main,
  },
  okCircle: {
    backgroundColor: theme.palette.success.main,
  },
  okText: {
    color: theme.palette.success.main,
  },
  unknownText: {
    color: theme.palette.text.disabled,
  },
  unknownCircle: {
    backgroundColor: theme.palette.text.disabled,
  },
  paddingRightFive: {
    paddingRight: 5,
    paddingLeft: 5,
  },

  editIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    '& svg': {
      width: 18,
      height: 18,
      cursor: 'pointer',
      fill: '#BABDCC',
      opacity: 0.8,

      '&:hover': {
        opacity: 1,
        transition: 'fill 200ms',
      },
    },
  },

  emailIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    '& svg': {
      width: 20,
      height: 20,
      cursor: 'pointer',
      opacity: 0.8,
      fill: theme.palette.success.main,

      '&:hover': {
        opacity: 1,
        transition: 'fill 200ms',
      },
    },
  },

  tableFilters: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.elitGray,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: '1px solid ' + theme.palette.border,
    marginBottom: 20,

    '& .MuiAccordionSummary-root': {
      padding: '0px 12px',
      height: '48px !important',
      minHeight: '48px !important',
      boxSizing: 'border-box',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '48px !important',
      minHeight: '48px !important',
      boxSizing: 'border-box',
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: '0 0 20px 0',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0 12px 12px 12px',
      display: 'block',
    },
  },

  tabsCursorDefault: {
    '& .MuiAccordionSummary-root.Mui-expanded': {
      cursor: 'default',
    },
  },

  formTitle: {
    fontSize: 16,
    fontWeight: 600,
  },

  roleChip: {
    fontSize: 12,
    margin: '0px 2px',
  },

  alignSelfEnd: {
    alignSelf: 'end',
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  contractAccordion: {
    boxSizing: 'border-box',
    width: '100%',
    border: 'none',
    marginBottom: 20,

    '& .MuiAccordionSummary-root': {
      padding: 0,
      height: '48px !important',
      minHeight: '48px !important',
      boxSizing: 'border-box',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '48px !important',
      minHeight: '48px !important',
      boxSizing: 'border-box',
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      padding: 0,
      margin: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      display: 'block',
    },
  },
  contractSectionTitle: {
    fontWeight: 600,
    fontSize: 14,
    paddingTop: 10,
    paddingLeft: 12,
    paddingBottom: 2,
  },
})

export default componentStyle
