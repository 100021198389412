import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import React from 'react'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomBox = (props) => {
  const {className, classes, ...rest} = props

  return <Box {...rest} className={cx(className, classes.fullWidth)} />
}

export default withStyles(materialStyle)(CustomBox)
