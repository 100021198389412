import componentStyle from 'component/componentStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getCustomerList} from 'redux/action/customersAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

let searchInterval = null

const CustomerPage = (props) => {
  const {
    classes,
    customerList,
    customerListLoading,
    customerListMeta,
    getCustomerList,
    rowsPerPage,
  } = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'name', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [searchValue, setSearchValue] = useState(null)

  const openDetail = (row) => () => {
    redirectTo('/customer/' + row.id)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'name',
      sortKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'ic',
      sortKey: 'ic',
      label: <Trans>IC</Trans>,
    },
    {
      name: 'dic',
      sortKey: 'dic',
      label: <Trans>DIC</Trans>,
    },
    {
      name: 'cfs',
      sortKey: 'cfs',
      label: <Trans>Customer CFS only</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/customer/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      handleChangePage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getCustomerList(rowsPerPage, page * rowsPerPage, tableSort, searchValue).catch(() => {})
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={customerListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div className={classes.absoluteButton}>
          <CustomTooltip title={<Trans>New customer</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Add</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>
      <div className={classes.overFlowAuto}>
        <Table
          data={customerList}
          columns={columns}
          loading={customerListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          meta={customerListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

CustomerPage.propTypes = {
  classes: PropTypes.object,
  customerList: PropTypes.array,
  customerListLoading: PropTypes.bool,
  customerListMeta: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getCustomerList: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      customerList: store.customer.customerList,
      customerListMeta: store.customer.customerListMeta,
      customerListLoading: store.customer.customerListLoading,
      rowsPerPage: store.globalSettings.rowsPerPage,
    }
  }, mapDispatchToProps)
)(CustomerPage)
