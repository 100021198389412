import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {ReactComponent as IcoDown} from 'style/asset/old/ico-down.svg'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'

/**
 * React-final-form needs to have values of multi select in type of number
 * therefore the array options for select may look like:
 * { 0: 'Analýza', 1: 'Návrh API' }
 */
const MultiSelectInput = (props) => {
  const {
    classes,
    label,
    input,
    meta,
    options,
    helperText,
    showHelperText = true,
    variant = 'outlined',
    onChange,
    values,
    loading,
    filters,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && meta.error
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div className={cx(classes.fullWidth, !filters && classes.inLineField)}>
      {label && (
        <div className={cx(classes.fieldLabel, filters && classes.filtersFieldLabel)}>{label}:</div>
      )}
      <FormControl error={!!errorMessage} variant={variant} className={classes.formControl}>
        <Select
          className={cx(
            classes.multiselect,
            classes.selectPositionRelativeClass,
            input?.value && classes.multiSelectPaddingEnhance
          )}
          multiple
          {...input}
          onChange={(e) => {
            input?.onChange(e)
            onChange && onChange(e)
          }}
          IconComponent={IcoDown}
          value={input ? [...input.value] : values}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  size={'small'}
                  key={value}
                  label={options[value]}
                  className={classes.multiSelectChip}
                />
              ))}
            </div>
          )}
          endAdornment={
            loading && (
              <InputAdornment position="end" className={classes.loadingEndAdornment}>
                <CircularProgress color="primary" />
              </InputAdornment>
            )
          }
          {...rest}
        >
          {Object.entries(options).map(([key, value], index) => (
            <MenuItem key={index} value={parseInt(key)} className={classes.multiSelectMenuItem}>
              <Checkbox
                checked={
                  input ? input.value.includes(parseInt(key)) : values.includes(parseInt(key))
                }
              />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
        </Select>
        {showHelperText && (
          <FormHelperText className={classes.formHelperText}>{errorHelperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  )
}

MultiSelectInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.array,
  loading: PropTypes.bool,
}

export default withStyles(fieldStyle)(MultiSelectInput)
