import componentStyle from 'component/componentStyle'
import React, {useState} from 'react'
import {compose} from 'redux'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ContractAccordion = (props) => {
  const {classes, title, content, defaultExpanded = false} = props

  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <Accordion
      className={classes.contractAccordion}
      elevation={0}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.contractSectionTitle}>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  )
}

export default compose(
  withStyles((theme) => ({
    ...componentStyle(theme),
  }))
)(ContractAccordion)
