import cx from 'classnames'
import detailPageStyle from 'component/detailPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Loader from 'component/material/Loader'
import Table from 'component/material/table/Table'
import download from 'downloadjs'
import {DATE_FORMAT} from 'helper/constants'
import {getContentDispositionFilename} from 'helper/functions'
import moment from 'moment/moment'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {
  downloadSupAdjustment,
  downloadUnsignedSupAdjustment,
  getSupContractAdjustments,
} from 'redux/action/suppliersAction'
import {getTermList} from 'redux/action/termsAction'
import buttonStyle from 'style/components/buttonStyle'
import {Trans} from '@lingui/macro'
import {Link, Typography} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const FleetTermsDialog = (props) => {
  const {
    classes,
    open,
    onClose,
    row,
    getTermList,
    getSupContractAdjustments,
    termListLoading,
    termList,
    adjustmentListLoading,
    adjustmentListData,
    downloadSupAdjustment,
    downloadUnsignedSupAdjustment,
  } = props

  let {id} = useParams()

  const handleDownloadAdjustment = (adjustmentId) => {
    downloadSupAdjustment(id, adjustmentId).then((res) => {
      const filename = getContentDispositionFilename(res.headers)
      const data = res.data
      download(data, filename, 'application/pdf')

      // create blob from array buffer in order to create url and then open it in new tab
      const blob = new Blob([data], {type: 'application/pdf'})
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    })
  }

  const handleDownloadUnsignedAdjustment = (adjustmentId) => {
    downloadUnsignedSupAdjustment(id, adjustmentId).then((res) => {
      const filename = getContentDispositionFilename(res.headers)
      const data = res.data
      download(data, filename, 'application/pdf')

      // create blob from array buffer in order to create url and then open it in new tab
      const blob = new Blob([data], {type: 'application/pdf'})
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    })
  }

  const adjustmentColumns = [
    {
      name: 'signed_file',
      label: <Trans>Name</Trans>,
      render: (val, row) =>
        val ? (
          <Link
            className={classes.cursorPointer}
            onClick={() => row.id && handleDownloadAdjustment(row.id)}
          >
            {val.filename}
          </Link>
        ) : (
          <Link
            className={classes.cursorPointer}
            onClick={() => row.id && handleDownloadUnsignedAdjustment(row.id)}
          >
            {row.unsigned_file?.filename || <Trans>Download contract adjustment preview</Trans>}
          </Link>
        ),
    },
    {
      name: 'created_at',
      label: <Trans>Created at</Trans>,
      render: (val) => <span>{val && moment(val).format(DATE_FORMAT)}</span>,
    },
    {
      name: 'signed_at',
      label: <Trans>Signed at</Trans>,
      render: (val) => <span>{val && moment(val).format(DATE_FORMAT)}</span>,
    },
  ]

  useEffect(() => {
    if (open && row.id) {
      getTermList(100, 0, {}, null, {
        fleet_id: row.id,
      }).catch(() => {})
      getSupContractAdjustments(id, {fleet_id: row.id}).catch(() => {})
    }
  }, [open, row])

  return (
    <DialogWindow
      title={<Trans>Terms of cooperation</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
      fullWidth={true}
    >
      {termListLoading && <Loader />}

      {termList?.map((t, i) => (
        <div key={t.id}>
          {i === 0 && (
            <GridContainer style={{borderBottom: '1px solid black', marginBottom: '4px'}}>
              <GridItem xs={6}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Type of work</Trans>
                </Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Price per unit</Trans>
                </Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Insured event</Trans>
                </Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Region</Trans>
                </Typography>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer spacing={2} className={i % 2 ? classes.lightBackgroundColor : null}>
            <GridItem xs={6}>
              <Typography variant={'body2'}>{t.type_of_work}</Typography>
            </GridItem>
            <GridItem xs={2}>
              <Typography variant={'body2'}>{`${Math.round(t.price * 100) / 100} ${
                t.unit
              }`}</Typography>
            </GridItem>
            <GridItem xs={2}>
              <Typography variant={'body2'}>
                {t.insured_event ? <Trans>Yes</Trans> : <Trans>No</Trans>}
              </Typography>
            </GridItem>
            <GridItem xs={2}>
              <Typography variant={'body2'}>{t.pretty_region ? t.pretty_region : '---'}</Typography>
            </GridItem>
          </GridContainer>
        </div>
      ))}

      <GridContainer>
        <BoxFullWidth pt={6}>
          <Typography variant={'h6'} color={'primary'} align={'center'}>
            <Trans>History of contract adjustments</Trans>
          </Typography>
        </BoxFullWidth>
        <Table
          data={adjustmentListData}
          columns={adjustmentColumns}
          loading={adjustmentListLoading}
          pagination={false}
          className={cx(classes.lastRowRoundBorder, classes.detailTable)}
        />
      </GridContainer>
    </DialogWindow>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTermList,
      getSupContractAdjustments,
      downloadSupAdjustment,
      downloadUnsignedSupAdjustment,
    },
    dispatch
  )
}

export default compose(
  withStyles((theme) => ({
    ...buttonStyle(theme),
    ...detailPageStyle(theme),
  })),
  connect((store) => {
    return {
      termListLoading: store.term.termListLoading,
      termList: store.term.termList,
      adjustmentListLoading: store.supplier.adjustmentListLoading,
      adjustmentListData: store.supplier.adjustmentListData,
    }
  }, mapDispatchToProps)
)(FleetTermsDialog)
