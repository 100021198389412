import {
  GET_CFS_CHANGELOG_FAIL,
  GET_CFS_CHANGELOG_REQUEST,
  GET_CFS_CHANGELOG_SUCCESS,
  GET_CFS_FAIL,
  GET_CFS_LIST_FAIL,
  GET_CFS_LIST_REQUEST,
  GET_CFS_LIST_SUCCESS,
  GET_CFS_REQUEST,
  GET_CFS_STATUS_FAIL,
  GET_CFS_STATUS_REQUEST,
  GET_CFS_STATUS_SUCCESS,
  GET_CFS_SUCCESS,
} from 'redux/actionType'

const initState = {
  cfsList: [],
  cfsListLoading: false,
  cfsListMeta: {},
  cfs: {},
  cfsLoading: false,
  invoiceStatusList: [],
  invoiceStatusListLoading: false,
  invoiceStatusListMeta: {},
  changeLog: [],
  changeLogLoading: false,
  changeLogMeta: {},
}

const cfsReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_CFS_LIST_REQUEST: {
      return {
        ...state,
        cfsListLoading: true,
      }
    }
    case GET_CFS_LIST_SUCCESS: {
      return {
        ...state,
        cfsListLoading: false,
        cfsList: action.data?.objects,
        cfsListMeta: action.data?.meta,
      }
    }
    case GET_CFS_LIST_FAIL: {
      return {
        ...state,
        cfsListLoading: false,
      }
    }
    case GET_CFS_REQUEST: {
      return {
        ...state,
        cfs: {},
        cfsLoading: true,
      }
    }
    case GET_CFS_SUCCESS: {
      return {
        ...state,
        cfs: action.data,
        cfsLoading: false,
      }
    }
    case GET_CFS_FAIL: {
      return {
        ...state,
        cfsLoading: false,
      }
    }
    case GET_CFS_STATUS_REQUEST: {
      return {
        ...state,
        invoiceStatusListLoading: true,
      }
    }
    case GET_CFS_STATUS_SUCCESS: {
      return {
        ...state,
        invoiceStatusListLoading: false,
        invoiceStatusList: action.data.objects,
        invoiceStatusListMeta: action.data.meta,
      }
    }
    case GET_CFS_STATUS_FAIL: {
      return {
        ...state,
        invoiceStatusListLoading: false,
      }
    }
    case GET_CFS_CHANGELOG_REQUEST: {
      return {
        ...state,
        changeLog: [],
        changeLogLoading: true,
      }
    }
    case GET_CFS_CHANGELOG_SUCCESS: {
      return {
        ...state,
        changeLogLoading: false,
        changeLog: action.data.objects,
        changeLogMeta: action.data.meta,
      }
    }
    case GET_CFS_CHANGELOG_FAIL: {
      return {
        ...state,
        changeLogLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default cfsReducer
