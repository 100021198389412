import {
  GET_PRED_TERM_FAIL,
  GET_PRED_TERM_LIST_FAIL,
  GET_PRED_TERM_LIST_REQUEST,
  GET_PRED_TERM_LIST_SUCCESS,
  GET_PRED_TERM_REQUEST,
  GET_PRED_TERM_SUCCESS,
} from 'redux/actionType'

const initState = {
  predefinedTermList: [],
  predefinedTermListLoading: false,
  predefinedTermListMeta: {},
  predefinedTerm: {},
  predefinedTermLoading: false,
}

const predefinedTermReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_PRED_TERM_LIST_REQUEST: {
      return {
        ...state,
        predefinedTermListLoading: true,
      }
    }
    case GET_PRED_TERM_LIST_SUCCESS: {
      return {
        ...state,
        predefinedTermListLoading: false,
        predefinedTermList: action.data?.objects,
        predefinedTermListMeta: action.data?.meta,
      }
    }
    case GET_PRED_TERM_LIST_FAIL: {
      return {
        ...state,
        predefinedTermListLoading: false,
      }
    }
    case GET_PRED_TERM_REQUEST: {
      return {
        ...state,
        predefinedTerm: {},
        predefinedTermLoading: true,
      }
    }
    case GET_PRED_TERM_SUCCESS: {
      return {
        ...state,
        predefinedTerm: action.data,
        predefinedTermLoading: false,
      }
    }
    case GET_PRED_TERM_FAIL: {
      return {
        ...state,
        predefinedTermLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default predefinedTermReducer
