import {
  GET_STATUS_FAIL,
  GET_STATUS_LIST_FAIL,
  GET_STATUS_LIST_REQUEST,
  GET_STATUS_LIST_SUCCESS,
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
} from 'redux/actionType'

const initState = {
  statusList: [],
  statusListLoading: false,
  statusListMeta: {},
  status: {},
  statusLoading: false,
}

const statusReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_STATUS_LIST_REQUEST: {
      return {
        ...state,
        statusListLoading: true,
      }
    }
    case GET_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        statusListLoading: false,
        statusList: action.data?.objects,
        statusListMeta: action.data?.meta,
      }
    }
    case GET_STATUS_LIST_FAIL: {
      return {
        ...state,
        statusListLoading: false,
      }
    }
    case GET_STATUS_REQUEST: {
      return {
        ...state,
        status: {},
        statusLoading: true,
      }
    }
    case GET_STATUS_SUCCESS: {
      return {
        ...state,
        status: action.data,
        statusLoading: false,
      }
    }
    case GET_STATUS_FAIL: {
      return {
        ...state,
        statusLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default statusReducer
