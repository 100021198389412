import {
  GET_UNIT_FAIL,
  GET_UNIT_LIST_FAIL,
  GET_UNIT_LIST_REQUEST,
  GET_UNIT_LIST_SUCCESS,
  GET_UNIT_REQUEST,
  GET_UNIT_SUCCESS,
} from 'redux/actionType'

const initState = {
  unitList: [],
  unitListLoading: false,
  unitListMeta: {},
  unit: {},
  unitLoading: false,
}

const unitReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_UNIT_LIST_REQUEST: {
      return {
        ...state,
        unitListLoading: true,
      }
    }
    case GET_UNIT_LIST_SUCCESS: {
      return {
        ...state,
        unitListLoading: false,
        unitList: action.data?.objects,
        unitListMeta: action.data?.meta,
      }
    }
    case GET_UNIT_LIST_FAIL: {
      return {
        ...state,
        unitListLoading: false,
      }
    }
    case GET_UNIT_REQUEST: {
      return {
        ...state,
        unitLoading: true,
      }
    }
    case GET_UNIT_SUCCESS: {
      return {
        ...state,
        unit: action.data,
        unitLoading: false,
      }
    }
    case GET_UNIT_FAIL: {
      return {
        ...state,
        unitLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default unitReducer
