import cx from 'classnames'
import componentStyle from 'component/componentStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import WarningDialog from 'component/material/WarningDialog'
import Table from 'component/material/table/Table'
import {EDI_RESET_PSW_URL, INVOICES_RESET_PSW_URL} from 'helper/constants'
import {fireErrorToast, fireSuccessToast, isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {welcomeUser} from 'redux/action/authAction'
import {deleteUser, getUserList} from 'redux/action/userAction'
import {ReactComponent as Delete} from 'style/asset/bin.svg'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import {Trans} from '@lingui/macro'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import {Email} from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

let intervalId = null

const UsersPage = (props) => {
  const {
    classes,
    rowsPerPage,
    getUserList,
    userList,
    userListLoading,
    userListMeta,
    deleteUser,
    welcomeUser,
  } = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'name', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [openDeleteDialog, setOpenDeleteDialog] = useState({visible: false, user: null})
  const [filters, setFilters] = useState({})
  const [filtersTemp, setFiltersTemp] = useStateWithCallbackLazy({})
  const [sendWelcomeMailDialog, setSendWelcomeMailDialog] = useState({visible: false, user: null})

  const onFilterChange = (e) => {
    const value = e.target?.value
    const name = e.target?.name
    setFiltersTemp(
      (prevState) => ({...prevState, [name]: value}),
      (value) => {
        clearTimeout(intervalId)
        intervalId = setTimeout(() => {
          handleChangePage(0)
          setFilters(value)
        }, 500)
      }
    )
  }

  const handleSubmit = () => {
    // empty submit method, there is no need for form submit, filtering is on change
    // but still it is easier to implement fields with in the form
  }

  const handleOpenDialog = (user) => () => {
    setOpenDeleteDialog({visible: true, user: user})
  }

  const handleCloseDialog = () => {
    setOpenDeleteDialog({visible: false, user: null})
  }

  const onConfirm = () => {
    return deleteUser(openDeleteDialog.user.id)
      .then(() => {
        fireSuccessToast(<Trans>User deleted.</Trans>)
        getUserList(rowsPerPage, page * rowsPerPage, tableSort)
        setOpenDeleteDialog({visible: false, user: null})
      })
      .catch(() => {
        fireErrorToast(<Trans>User deletion failed.</Trans>)
      })
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'name',
      sortKey: 'name',
      label: <Trans>User name</Trans>,
    },
    {
      name: 'username',
      sortKey: 'username',
      label: <Trans>Username</Trans>,
    },
    {
      name: 'email',
      sortKey: 'email',
      label: <Trans>E-Mail</Trans>,
    },
    {
      name: 'roles',
      label: <Trans>Roles</Trans>,
      render: (val) => {
        return (
          <GridContainer>
            {val.map((role, index) => {
              return (
                <Chip key={index} size="small" label={role.name} className={classes.roleChip} />
              )
            })}
          </GridContainer>
        )
      },
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '120px',
      render: (val, row) => (
        <GridContainer wrap={'nowrap'} direction={'row'}>
          <GridItem xs={4}>
            {!row?.welcomed_at && (
              <div
                className={cx(classes.editIcon, classes.emailIcon)}
                onClick={() => setSendWelcomeMailDialog({visible: true, user: row})}
              >
                <Email />
              </div>
            )}
          </GridItem>
          <GridItem xs={4}>
            <div className={classes.editIcon} onClick={openDetail(row)}>
              <Edit />
            </div>
          </GridItem>
          <GridItem xs={4}>
            <div className={classes.editIcon} onClick={handleOpenDialog(row)}>
              <Delete />
            </div>
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const openDetail = (row) => (e) => {
    redirectTo('/user/' + row.id)
  }

  const openNew = () => {
    redirectTo('/user/new')
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getUserList(rowsPerPage, page * rowsPerPage, tableSort, filters).catch(() => {})
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(intervalId)
    }
  }, [page, rowsPerPage, tableSort, filters])

  return (
    <>
      <WarningDialog
        open={openDeleteDialog.visible}
        message={openDeleteDialog?.user?.name}
        title={<Trans>User delete</Trans>}
        onClose={handleCloseDialog}
        onConfirm={onConfirm}
      />

      <WarningDialog
        open={sendWelcomeMailDialog.visible}
        message={sendWelcomeMailDialog?.user?.email}
        title={<Trans>Send welcome e-mail</Trans>}
        onClose={() => setSendWelcomeMailDialog({visible: false, user: null})}
        onConfirm={() => {
          const pswUrl =
            sendWelcomeMailDialog?.user?.roles?.length === 1 &&
            sendWelcomeMailDialog?.user?.roles?.[0]?.id === 6
              ? INVOICES_RESET_PSW_URL
              : EDI_RESET_PSW_URL

          welcomeUser(sendWelcomeMailDialog?.user?.id, {reset_password_url: pswUrl})
          setSendWelcomeMailDialog({visible: false, user: null})
        }}
      />

      <Accordion className={classes.tableFilters} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <BoxFullWidth className={classes.formTitle}>
            <Trans>Filters</Trans>
          </BoxFullWidth>
        </AccordionSummary>
        <AccordionDetails>
          <Form onSubmit={handleSubmit}>
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <GridContainer
                  spacing={4}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <GridItem container xs={12} sm={6} md={4} lg={3}>
                    <Field
                      disabled={formProps.submitting}
                      name="username"
                      label={<Trans>Username</Trans>}
                      component={TextInput}
                      onChange={onFilterChange}
                      showHelperText={false}
                      filters={true}
                    />
                  </GridItem>
                  <GridItem container xs={12} sm={6} md={4} lg={3}>
                    <Field
                      disabled={formProps.submitting}
                      name="email"
                      label={<Trans>E-mail</Trans>}
                      component={TextInput}
                      onChange={onFilterChange}
                      showHelperText={false}
                      filters={true}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Form>
        </AccordionDetails>
      </Accordion>

      <BoxFullWidth>
        <div className={classes.absoluteButtonUsers}>
          <PrimaryButton
            fullWidth={false}
            textPadding={0}
            text={<Trans>Add</Trans>}
            onClick={openNew}
          />
        </div>
        <div className={classes.overFlowAuto}>
          <Table
            data={userList}
            columns={columns}
            loading={userListLoading}
            onTableColumnSort={handleTableSort}
            tableSort={tableSort}
            page={page}
            meta={userListMeta}
            onChangePage={handleChangePage}
          />
        </div>
      </BoxFullWidth>
    </>
  )
}

UsersPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  userList: PropTypes.array,
  userListLoading: PropTypes.bool,
  userListMeta: PropTypes.object,
  deleteUser: PropTypes.func,
  welcomeUser: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserList,
      deleteUser,
      welcomeUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      userList: store.user.userList,
      userListLoading: store.user.userListLoading,
      userListMeta: store.user.userListMeta,
    }
  }, mapDispatchToProps)
)(UsersPage)
