import {GET_ACCOUNTS_FAIL, GET_ACCOUNTS_REQUEST, GET_ACCOUNTS_SUCCESS} from 'redux/actionType'

const initState = {
  accountList: [],
  accountListLoading: false,
  accountListMeta: {},
}

const accountReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_ACCOUNTS_REQUEST: {
      return {
        ...state,
        accountListLoading: true,
      }
    }
    case GET_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accountListLoading: false,
        accountList: action.data?.objects,
        accountListMeta: action.data?.meta,
      }
    }
    case GET_ACCOUNTS_FAIL: {
      return {
        ...state,
        accountListLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default accountReducer
