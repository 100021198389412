import endpoints from 'helper/endpoints'
import {constructGetOrderBy, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_CUSTOMER_CONTRACT_TEMPLATE_FAIL,
  GET_CUSTOMER_CONTRACT_TEMPLATE_REQUEST,
  GET_CUSTOMER_CONTRACT_TEMPLATE_SUCCESS,
  GET_CUSTOMER_DEFAULT_TEMPLATE_FAIL,
  GET_CUSTOMER_DEFAULT_TEMPLATE_REQUEST,
  GET_CUSTOMER_DEFAULT_TEMPLATE_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_LIST_REQUEST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getCustomerList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.customers + searchQuery, {
        limit,
        offset,
        order_by: constructGetOrderBy(meta),
      })
      .then((res) => {
        dispatch({type: GET_CUSTOMER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCustomerListBulkExportable = (limit = 1000, offset = 0, meta) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_LIST_REQUEST})
    return httpClient
      .get(endpoints.customersHasBulkExportableInvoices, {
        limit: limit,
        offset: offset,
        order_by: constructGetOrderBy(meta),
      })
      .then((res) => {
        dispatch({type: GET_CUSTOMER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCustomer = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_REQUEST})
    return httpClient
      .get(endpoints.customers + '/' + id)
      .then((res) => {
        dispatch({type: GET_CUSTOMER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createCustomer = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.customers, data)
      .then((res) => {
        fireSuccessToast(<Trans>New customer created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchCustomer = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.customers + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Customer updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getCustomerDefaultAdjustmentTemplates = (id, limit = 500, offset = 0) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_DEFAULT_TEMPLATE_REQUEST})
    return httpClient
      .get(`${endpoints.customers}/${id}/default-contract-adjustment-template`, {
        limit,
        offset,
      })
      .then((res) => {
        dispatch({type: GET_CUSTOMER_DEFAULT_TEMPLATE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_DEFAULT_TEMPLATE_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}
export const getCustomerContractAdjustmentTemplates = (id, limit = 500, offset = 0) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_CONTRACT_TEMPLATE_REQUEST})
    return httpClient
      .get(`${endpoints.customers}/${id}/contract-adjustment-templates`, {
        limit,
        offset,
      })
      .then((res) => {
        dispatch({type: GET_CUSTOMER_CONTRACT_TEMPLATE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_CONTRACT_TEMPLATE_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const postCustomerContractAdjustmentTemplates = (id, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.customers}/${id}/contract-adjustment-templates`, data)
      .then((res) => {
        fireSuccessToast(<Trans>New contract adjustment template created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchCustomerContractAdjustmentTemplates = (id, templateId, data) => {
  return (dispatch) => {
    return httpClient
      .patch(`${endpoints.customers}/${id}/contract-adjustment-templates/${templateId}`, data)
      .then((res) => {
        fireSuccessToast(<Trans>Contract adjustment template updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const downloadContractAdjustmentTemplatePreview = (id, templateId) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.customers}/${id}/contract-adjustment-templates/${templateId}/download-preview`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}
