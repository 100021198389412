import {
  GET_TERM_FAIL,
  GET_TERM_LIST_FAIL,
  GET_TERM_LIST_REQUEST,
  GET_TERM_LIST_SUCCESS,
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
} from 'redux/actionType'

const initState = {
  termList: [],
  termListLoading: false,
  termListMeta: {},
  term: {},
  termLoading: false,
}

const termReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_TERM_LIST_REQUEST: {
      return {
        ...state,
        termListLoading: true,
      }
    }
    case GET_TERM_LIST_SUCCESS: {
      return {
        ...state,
        termListLoading: false,
        termList: action.data?.objects,
        termListMeta: action.data?.meta,
      }
    }
    case GET_TERM_LIST_FAIL: {
      return {
        ...state,
        termListLoading: false,
      }
    }
    case GET_TERM_REQUEST: {
      return {
        ...state,
        termLoading: true,
      }
    }
    case GET_TERM_SUCCESS: {
      return {
        ...state,
        term: action.data,
        termLoading: false,
      }
    }
    case GET_TERM_FAIL: {
      return {
        ...state,
        termLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default termReducer
