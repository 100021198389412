import catalogCs from 'App/locale/cs/messages'
import catalogEn from 'App/locale/en/messages'
import {cs, en} from 'make-plural'
import {setupI18n} from '@lingui/core'

const i18nProvider = setupI18n()

i18nProvider.load({
  en: catalogEn.messages,
  cs: catalogCs.messages,
})

i18nProvider.loadLocaleData('en', {plurals: en})
i18nProvider.loadLocaleData('cs', {plurals: cs})

i18nProvider.activate('cs')

// TEXT USE CASE
// i18nProvider._(t`Monday`),

export default i18nProvider
