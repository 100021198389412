import {
  GET_CAR_FAIL,
  GET_CAR_LIST_FAIL,
  GET_CAR_LIST_REQUEST,
  GET_CAR_LIST_SUCCESS,
  GET_CAR_REQUEST,
  GET_CAR_SUCCESS,
} from 'redux/actionType'

const initState = {
  carList: [],
  carListLoading: false,
  carListMeta: {},
  car: {},
  carLoading: false,
}

const carReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_CAR_LIST_REQUEST: {
      return {
        ...state,
        carListLoading: true,
      }
    }
    case GET_CAR_LIST_SUCCESS: {
      return {
        ...state,
        carListLoading: false,
        carList: action.data?.objects,
        carListMeta: action.data?.meta,
      }
    }
    case GET_CAR_LIST_FAIL: {
      return {
        ...state,
        carListLoading: false,
      }
    }
    case GET_CAR_REQUEST: {
      return {
        ...state,
        car: {},
        carLoading: true,
      }
    }
    case GET_CAR_SUCCESS: {
      return {
        ...state,
        car: action.data,
        carLoading: false,
      }
    }
    case GET_CAR_FAIL: {
      return {
        ...state,
        carLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default carReducer
