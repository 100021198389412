import detailPageStyle from 'component/detailPageStyle'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getAreaOfWorkList} from 'redux/action/areasOfWorkAction'
import {createTypeOfWork, getTypeOfWork, patchTypeOfWork} from 'redux/action/typesOfWorkAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const TypeOfWorkDetail = (props) => {
  const {
    classes,
    patchTypeOfWork,
    getTypeOfWork,
    typeOfWork,
    typeOfWorkLoading,
    getAreaOfWorkList,
    areaOfWorkList,
    areaOfWorkListLoading,
    createTypeOfWork,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createTypeOfWork(preparedValues)
        .then((res) => {
          redirectTo('/type-of-work')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchTypeOfWork(id, preparedValues)
        .then((res) => {
          redirectTo('/type-of-work')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...typeOfWork,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getTypeOfWork(id).catch(() => {})
      }
      getAreaOfWorkList().catch(() => {})
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form onSubmit={handleSubmit} initialValues={id !== 'new' ? getInitialValues() : {}}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="description"
                      label={<Trans>Description</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="area_of_work_id"
                      label={<Trans>Area of work</Trans>}
                      component={SelectInput}
                      options={areaOfWorkList?.map((type) => ({
                        name: type.description,
                        value: type.id,
                      }))}
                      loading={areaOfWorkListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

TypeOfWorkDetail.propTypes = {
  classes: PropTypes.object,
  patchTypeOfWork: PropTypes.func,
  getTypeOfWork: PropTypes.func,
  getAreaOfWorkList: PropTypes.func,
  areaOfWorkList: PropTypes.array,
  areaOfWorkListLoading: PropTypes.bool,
  typeOfWork: PropTypes.array,
  typeOfWorkLoading: PropTypes.bool,
  createTypeOfWork: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAreaOfWorkList,
      getTypeOfWork,
      patchTypeOfWork,
      createTypeOfWork,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      typeOfWork: store.typeOfWork.typeOfWork,
      typeOfWorkLoading: store.typeOfWork.typeOfWorkLoading,
      areaOfWorkList: store.areaOfWork.areaOfWorkList,
      areaOfWorkListLoading: store.areaOfWork.areaOfWorkListLoading,
    }
  }, mapDispatchToProps)
)(TypeOfWorkDetail)
