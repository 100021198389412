import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import React from 'react'
import {components} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {ReactComponent as IcoDown} from 'style/asset/old/ico-down.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'

const AsyncInput = (props) => {
  const {
    label,
    name,
    filters,
    variant = 'outlined',
    loadOptions,
    onChange,
    disabled,
    classes,
    loading,
    isSearchable = false,
    isClearable = true,
    shouldLoadMore = true,
  } = props

  const IndicatorsContainer = (props) => {
    return (
      <div className={classes.asyncIndicatorContainer}>
        <components.IndicatorsContainer {...props} />
      </div>
    )
  }

  return (
    <BoxFullWidth className={cx(classes.fullWidth, !filters && classes.inLineField)}>
      {label && (
        <div className={cx(classes.fieldLabel, filters && classes.filtersFieldLabel)}>{label}</div>
      )}
      <FormControl
        disabled={disabled}
        variant={variant}
        className={cx(
          classes.formControl,
          classes.selectPositionRelativeClass,
          classes.asyncSelect
        )}
      >
        <AsyncPaginate
          shouldLoadMore={() => shouldLoadMore}
          isClearable={isClearable}
          isSearchable={isSearchable} // in case of adding search function, set to true and use search from loadOptions function
          isLoading={loading}
          reduceOptions={(previous, loaded) => loaded}
          name={name}
          loadOptions={loadOptions}
          onChange={(state) => onChange(name, state?.value || null)}
          className={cx(classes.selectInput, classes.inputStyles, classes.asyncSelectInput)}
          placeholder={''}
          components={{
            DropdownIndicator: IcoDown,
            IndicatorSeparator: null,
            IndicatorsContainer: IndicatorsContainer,
            LoadingIndicator: (props) => <CircularProgress {...props} delay={0} color="primary" />,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#004b80',
            },
          })}
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? '#004b80' : 'rgba(0, 0, 0, 0.23)',
                borderWidth: '1px',
                boxShadow: state.isFocused
                  ? '0px 0px 0px 1px #004b80'
                  : '0 2px 6px 0 rgb(103 103 139 / 10%)',
              }
            },
          }}
        />
      </FormControl>
    </BoxFullWidth>
  )
}

export default withStyles(fieldStyle)(AsyncInput)
