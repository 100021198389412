const loginPageStyle = (theme) => ({
  monitoringText: {
    paddingLeft: 20,
    color: theme.palette.text.disabled,
    fontSize: 10,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  loginBoxPadding: {
    padding: '48px 80px',
  },

  lkqLogo: {
    padding: '0px 24px 48px 24px',
    width: '100%',

    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
})

export default loginPageStyle
