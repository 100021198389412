import componentStyle from 'component/componentStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getRegionList} from 'redux/action/regionsAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

let searchInterval = null

const RegionsPage = (props) => {
  const {classes, rowsPerPage, getRegionList, regionList, regionListLoading, regionListMeta} = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'code', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [searchValue, setSearchValue] = useState(null)

  const openDetail = (row) => (e) => {
    redirectTo('/region/' + row.id)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'code',
      sortKey: 'code',
      label: <Trans>Code</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/region/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      handleChangePage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getRegionList(rowsPerPage, page * rowsPerPage, tableSort, searchValue).catch(() => {})
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={regionListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div>
          <CustomTooltip title={<Trans>New region</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Add</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>
      <div className={classes.overFlowAuto}>
        <Table
          data={regionList}
          columns={columns}
          loading={regionListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          meta={regionListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

RegionsPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getRegionList: PropTypes.func,
  regionList: PropTypes.array,
  regionListLoading: PropTypes.bool,
  regionListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRegionList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      regionList: store.region.regionList,
      regionListLoading: store.region.regionListLoading,
      regionListMeta: store.region.regionListMeta,
    }
  }, mapDispatchToProps)
)(RegionsPage)
