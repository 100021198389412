import Calendar from 'component/SuppliersPage/Calendar'
import componentStyle from 'component/componentStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getSupplierList} from 'redux/action/suppliersAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

let searchInterval = null

const SuppliersPage = (props) => {
  const {
    classes,
    rowsPerPage,
    getSupplierList,
    supplierList,
    supplierListLoading,
    supplierListMeta,
  } = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'name', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [searchValue, setSearchValue] = useState(null)
  const [openCalendar, setOpenCalendar] = useState({id: null, visible: false})

  const handleCloseCalendar = () => {
    setOpenCalendar({id: null, visible: false})
  }

  const openDetail = (row) => () => {
    redirectTo('/supplier/' + row.id)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'name',
      sortKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'customer_no',
      sortKey: 'customer_no',
      label: <Trans>Supplier no.</Trans>,
    },
    {
      name: 'ic',
      sortKey: 'ic',
      label: <Trans>IC</Trans>,
    },
    {
      name: 'dic',
      sortKey: 'dic',
      label: <Trans>DIC</Trans>,
    },
    {
      name: 'enabled',
      sortKey: 'enabled',
      label: <Trans>Enabled</Trans>,
      render: (val) => (val ? '' : <Trans>disabled</Trans>),
    },
    {
      name: 'branch_office',
      sortKey: 'branch_office',
      label: <Trans>Branch office</Trans>,
      render: (val) => (val ? <Trans>branch office</Trans> : <Trans>head office</Trans>),
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '80px',
      render: (val, row) => (
        <GridContainer wrap={'nowrap'} justifyContent={'space-between'}>
          <GridItem
            className={classes.editIcon}
            onClick={() => setOpenCalendar({id: row.id, visible: true})}
          >
            <DateRangeIcon />
          </GridItem>
          <GridItem className={classes.editIcon} onClick={openDetail(row)}>
            <Edit />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/supplier/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      handleChangePage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getSupplierList(rowsPerPage, page * rowsPerPage, tableSort, searchValue).catch(() => {})
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={supplierListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div>
          <CustomTooltip title={<Trans>New supplier</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Add</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>

      {/* CALENDAR DIALOG */}
      <DialogWindow
        title={<Trans>Setting unavailability of supplier</Trans>}
        fullWidth={false}
        open={openCalendar.visible}
        onClose={handleCloseCalendar}
      >
        <Calendar onClose={handleCloseCalendar} id={openCalendar.id} />
      </DialogWindow>

      <div className={classes.overFlowAuto}>
        <Table
          data={supplierList}
          columns={columns}
          loading={supplierListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          meta={supplierListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

SuppliersPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getSupplierList: PropTypes.func,
  supplierList: PropTypes.array,
  supplierListLoading: PropTypes.bool,
  supplierListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSupplierList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      supplierList: store.supplier.supplierList,
      supplierListLoading: store.supplier.supplierListLoading,
      supplierListMeta: store.supplier.supplierListMeta,
    }
  }, mapDispatchToProps)
)(SuppliersPage)
