import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {GET_BRANCHES_FAIL, GET_BRANCHES_REQUEST, GET_BRANCHES_SUCCESS} from 'redux/actionType'

export const getBranchList = (limit = 1000, offset = 0, meta) => {
  return (dispatch) => {
    dispatch({type: GET_BRANCHES_REQUEST})
    return httpClient
      .get(endpoints.branches, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_BRANCHES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_BRANCHES_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}
