import {
  GET_TYPE_OF_WORK_FAIL,
  GET_TYPE_OF_WORK_LIST_FAIL,
  GET_TYPE_OF_WORK_LIST_REQUEST,
  GET_TYPE_OF_WORK_LIST_SUCCESS,
  GET_TYPE_OF_WORK_REQUEST,
  GET_TYPE_OF_WORK_SUCCESS,
} from 'redux/actionType'

const initState = {
  typeOfWorkList: [],
  typeOfWorkListLoading: false,
  typeOfWorkListMeta: {},
  typeOfWork: {},
  typeOfWorkLoading: false,
}

const typeOfWorkReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_TYPE_OF_WORK_LIST_REQUEST: {
      return {
        ...state,
        typeOfWorkListLoading: true,
      }
    }
    case GET_TYPE_OF_WORK_LIST_SUCCESS: {
      return {
        ...state,
        typeOfWorkListLoading: false,
        typeOfWorkList: action.data?.objects,
        typeOfWorkListMeta: action.data?.meta,
      }
    }
    case GET_TYPE_OF_WORK_LIST_FAIL: {
      return {
        ...state,
        typeOfWorkListLoading: false,
      }
    }
    case GET_TYPE_OF_WORK_REQUEST: {
      return {
        ...state,
        typeOfWorkLoading: true,
      }
    }
    case GET_TYPE_OF_WORK_SUCCESS: {
      return {
        ...state,
        typeOfWork: action.data,
        typeOfWorkLoading: false,
      }
    }
    case GET_TYPE_OF_WORK_FAIL: {
      return {
        ...state,
        typeOfWorkLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default typeOfWorkReducer
