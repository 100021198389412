import {GET_BRANCHES_FAIL, GET_BRANCHES_REQUEST, GET_BRANCHES_SUCCESS} from 'redux/actionType'

const initState = {
  branchList: [],
  branchListLoading: false,
  branchListMeta: {},
}

const branchReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_BRANCHES_REQUEST: {
      return {
        ...state,
        branchListLoading: true,
      }
    }
    case GET_BRANCHES_SUCCESS: {
      return {
        ...state,
        branchListLoading: false,
        branchList: action.data?.objects,
        branchListMeta: action.data?.meta,
      }
    }
    case GET_BRANCHES_FAIL: {
      return {
        ...state,
        branchListLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default branchReducer
