import ArchiveContractDialog from 'component/SuppliersPage/ArchiveContractDialog'
import NewContractDialog from 'component/SuppliersPage/NewContractDialog'
import detailPageStyle from 'component/detailPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import download from 'downloadjs'
import {fireWarningToast, getContentDispositionFilename} from 'helper/functions'
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {downloadSupContract, getSupContracts} from 'redux/action/suppliersAction'
import buttonStyle from 'style/components/buttonStyle'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const SupplierDetailDocuments = (props) => {
  const {classes, getSupContracts, downloadSupContract} = props

  let {id} = useParams()

  const [openNewDialog, setOpenNewDialog] = useState(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)

  const openGeneralContract = () => {
    getSupContracts(id, false).then((res) => {
      if (res?.objects?.[0]?.id) {
        downloadSupContract(id, res?.objects?.[0]?.id)
          .then((res) => {
            const filename = getContentDispositionFilename(res.headers)
            const data = res.data
            download(data, filename, 'application/pdf')

            // create blob from array buffer in order to create url and then open it in new tab
            const blob = new Blob([data], {type: 'application/pdf'})
            const url = URL.createObjectURL(blob)
            window.open(url, '_blank')
          })
          .catch(() => {})
      } else {
        fireWarningToast(<Trans>Supplier does not have any active general contract.</Trans>)
      }
    })
  }

  const handleOpenNewDialog = () => {
    setOpenNewDialog(true)
  }
  const handleCloseNewDialog = () => {
    setOpenNewDialog(false)
  }
  const handleOpenArchiveDialog = () => {
    setOpenArchiveDialog(true)
  }
  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false)
  }

  return (
    <>
      <GridItem container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
        <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
          <Trans>Documents</Trans>
        </BoxFullWidth>
        <GridContainer spacing={4}>
          <GridItem>
            <PrimaryButton
              fullWidth={false}
              size="small"
              text={<Trans>Open general contract</Trans>}
              onClick={openGeneralContract}
            />
          </GridItem>
          <GridItem>
            <PrimaryButton
              fullWidth={false}
              size="small"
              className={classes.successButton}
              text={<Trans>New general contract</Trans>}
              onClick={handleOpenNewDialog}
            />
          </GridItem>
          <GridItem>
            <PrimaryButton
              fullWidth={false}
              size="small"
              className={classes.warningButton}
              text={<Trans>Archive</Trans>}
              onClick={handleOpenArchiveDialog}
            />
          </GridItem>
        </GridContainer>
      </GridItem>

      <NewContractDialog
        classes={classes}
        id={id}
        onClose={handleCloseNewDialog}
        open={openNewDialog}
      />

      <ArchiveContractDialog
        classes={classes}
        id={id}
        onClose={handleCloseArchiveDialog}
        open={openArchiveDialog}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({getSupContracts, downloadSupContract}, dispatch)
}

export default compose(
  withStyles((theme) => ({
    ...detailPageStyle(theme),
    ...buttonStyle(theme),
  })),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(SupplierDetailDocuments)
