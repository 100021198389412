import {
  GET_ATTACHMENT_TYPE_FAIL,
  GET_ATTACHMENT_TYPE_LIST_FAIL,
  GET_ATTACHMENT_TYPE_LIST_REQUEST,
  GET_ATTACHMENT_TYPE_LIST_SUCCESS,
  GET_ATTACHMENT_TYPE_REQUEST,
  GET_ATTACHMENT_TYPE_SUCCESS,
} from 'redux/actionType'

const initState = {
  attachmentTypeList: [],
  attachmentTypeListLoading: false,
  attachmentTypeListMeta: {},
  attachmentType: {},
  attachmentTypeLoading: false,
}

const attachmentTypeReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_ATTACHMENT_TYPE_LIST_REQUEST: {
      return {
        ...state,
        attachmentTypeListLoading: true,
      }
    }
    case GET_ATTACHMENT_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        attachmentTypeListLoading: false,
        attachmentTypeList: action.data?.objects,
        attachmentTypeListMeta: action.data?.meta,
      }
    }
    case GET_ATTACHMENT_TYPE_LIST_FAIL: {
      return {
        ...state,
        attachmentTypeListLoading: false,
      }
    }
    case GET_ATTACHMENT_TYPE_REQUEST: {
      return {
        ...state,
        attachmentType: {},
        attachmentTypeLoading: true,
      }
    }
    case GET_ATTACHMENT_TYPE_SUCCESS: {
      return {
        ...state,
        attachmentType: action.data,
        attachmentTypeLoading: false,
      }
    }
    case GET_ATTACHMENT_TYPE_FAIL: {
      return {
        ...state,
        attachmentTypeLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default attachmentTypeReducer
