// auth action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

// cfs
export const GET_CFS_LIST_REQUEST = 'GET_CFS_LIST_REQUEST'
export const GET_CFS_LIST_SUCCESS = 'GET_CFS_LIST_SUCCESS'
export const GET_CFS_LIST_FAIL = 'GET_CFS_LIST_FAIL'
export const GET_CFS_REQUEST = 'GET_CFS_REQUEST'
export const GET_CFS_SUCCESS = 'GET_CFS_SUCCESS'
export const GET_CFS_FAIL = 'GET_CFS_FAIL'
export const GET_CFS_STATUS_REQUEST = 'GET_CFS_STATUS_REQUEST'
export const GET_CFS_STATUS_SUCCESS = 'GET_CFS_STATUS_SUCCESS'
export const GET_CFS_STATUS_FAIL = 'GET_CFS_STATUS_FAIL'
export const GET_CFS_CHANGELOG_REQUEST = 'GET_CFS_CHANGELOG_REQUEST'
export const GET_CFS_CHANGELOG_SUCCESS = 'GET_CFS_CHANGELOG_SUCCESS'
export const GET_CFS_CHANGELOG_FAIL = 'GET_CFS_CHANGELOG_FAIL'

// customers
export const GET_CUSTOMER_LIST_REQUEST = 'GET_CUSTOMER_LIST_REQUEST'
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS'
export const GET_CUSTOMER_LIST_FAIL = 'GET_CUSTOMER_LIST_FAIL'
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL'
export const GET_CUSTOMER_CONTRACT_TEMPLATE_REQUEST = 'GET_CUSTOMER_CONTRACT_TEMPLATE_REQUEST'
export const GET_CUSTOMER_CONTRACT_TEMPLATE_SUCCESS = 'GET_CUSTOMER_CONTRACT_TEMPLATE_SUCCESS'
export const GET_CUSTOMER_CONTRACT_TEMPLATE_FAIL = 'GET_CUSTOMER_CONTRACT_TEMPLATE_FAIL'
export const GET_CUSTOMER_DEFAULT_TEMPLATE_REQUEST = 'GET_CUSTOMER_DEFAULT_TEMPLATE_REQUEST'
export const GET_CUSTOMER_DEFAULT_TEMPLATE_SUCCESS = 'GET_CUSTOMER_DEFAULT_TEMPLATE_SUCCESS'
export const GET_CUSTOMER_DEFAULT_TEMPLATE_FAIL = 'GET_CUSTOMER_DEFAULT_TEMPLATE_FAIL'

// providers
export const GET_PROVIDER_LIST_REQUEST = 'GET_PROVIDER_LIST_REQUEST'
export const GET_PROVIDER_LIST_SUCCESS = 'GET_PROVIDER_LIST_SUCCESS'
export const GET_PROVIDER_LIST_FAIL = 'GET_PROVIDER_LIST_FAIL'
export const GET_PROVIDER_REQUEST = 'GET_PROVIDER_REQUEST'
export const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS'
export const GET_PROVIDER_FAIL = 'GET_PROVIDER_FAIL'

// fleets
export const GET_FLEET_LIST_REQUEST = 'GET_FLEET_LIST_REQUEST'
export const GET_FLEET_LIST_SUCCESS = 'GET_FLEET_LIST_SUCCESS'
export const GET_FLEET_LIST_FAIL = 'GET_FLEET_LIST_FAIL'
export const GET_FLEET_REQUEST = 'GET_FLEET_REQUEST'
export const GET_FLEET_SUCCESS = 'GET_FLEET_SUCCESS'
export const GET_FLEET_FAIL = 'GET_FLEET_FAIL'
export const GET_FLEET_MAP_REQUEST = 'GET_FLEET_MAP_REQUEST'
export const GET_FLEET_MAP_SUCCESS = 'GET_FLEET_MAP_SUCCESS'
export const GET_FLEET_MAP_FAIL = 'GET_FLEET_MAP_FAIL'

// accounts
export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL'

// branches
export const GET_BRANCHES_REQUEST = 'GET_BRANCHES_REQUEST'
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS'
export const GET_BRANCHES_FAIL = 'GET_BRANCHES_FAIL'

// terms
export const GET_TERM_LIST_REQUEST = 'GET_TERM_LIST_REQUEST'
export const GET_TERM_LIST_SUCCESS = 'GET_TERM_LIST_SUCCESS'
export const GET_TERM_LIST_FAIL = 'GET_TERM_LIST_FAIL'
export const GET_TERM_REQUEST = 'GET_TERM_REQUEST'
export const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS'
export const GET_TERM_FAIL = 'GET_TERM_FAIL'

// predefined terms
export const GET_PRED_TERM_LIST_REQUEST = 'GET_PRED_TERM_LIST_REQUEST'
export const GET_PRED_TERM_LIST_SUCCESS = 'GET_PRED_TERM_LIST_SUCCESS'
export const GET_PRED_TERM_LIST_FAIL = 'GET_PRED_TERM_LIST_FAIL'
export const GET_PRED_TERM_REQUEST = 'GET_PRED_TERM_REQUEST'
export const GET_PRED_TERM_SUCCESS = 'GET_PRED_TERM_SUCCESS'
export const GET_PRED_TERM_FAIL = 'GET_PRED_TERM_FAIL'

// types of work
export const GET_TYPE_OF_WORK_LIST_REQUEST = 'GET_TYPE_OF_WORK_LIST_REQUEST'
export const GET_TYPE_OF_WORK_LIST_SUCCESS = 'GET_TYPE_OF_WORK_LIST_SUCCESS'
export const GET_TYPE_OF_WORK_LIST_FAIL = 'GET_TYPE_OF_WORK_LIST_FAIL'
export const GET_TYPE_OF_WORK_REQUEST = 'GET_TYPE_OF_WORK_REQUEST'
export const GET_TYPE_OF_WORK_SUCCESS = 'GET_TYPE_OF_WORK_SUCCESS'
export const GET_TYPE_OF_WORK_FAIL = 'GET_TYPE_OF_WORK_FAIL'

// areas of work
export const GET_AREA_OF_WORK_LIST_REQUEST = 'GET_AREA_OF_WORK_LIST_REQUEST'
export const GET_AREA_OF_WORK_LIST_SUCCESS = 'GET_AREA_OF_WORK_LIST_SUCCESS'
export const GET_AREA_OF_WORK_LIST_FAIL = 'GET_AREA_OF_WORK_LIST_FAIL'
export const GET_AREA_OF_WORK_REQUEST = 'GET_AREA_OF_WORK_REQUEST'
export const GET_AREA_OF_WORK_SUCCESS = 'GET_AREA_OF_WORK_SUCCESS'
export const GET_AREA_OF_WORK_FAIL = 'GET_AREA_OF_WORK_FAIL'

// units
export const GET_UNIT_LIST_REQUEST = 'GET_UNIT_LIST_REQUEST'
export const GET_UNIT_LIST_SUCCESS = 'GET_UNIT_LIST_SUCCESS'
export const GET_UNIT_LIST_FAIL = 'GET_UNIT_LIST_FAIL'
export const GET_UNIT_REQUEST = 'GET_UNIT_REQUEST'
export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS'
export const GET_UNIT_FAIL = 'GET_UNIT_FAIL'

// regions
export const GET_REGION_LIST_REQUEST = 'GET_REGION_LIST_REQUEST'
export const GET_REGION_LIST_SUCCESS = 'GET_REGION_LIST_SUCCESS'
export const GET_REGION_LIST_FAIL = 'GET_REGION_LIST_FAIL'
export const GET_REGION_REQUEST = 'GET_REGION_REQUEST'
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS'
export const GET_REGION_FAIL = 'GET_REGION_FAIL'

// suppliers
export const GET_SUPPLIER_LIST_REQUEST = 'GET_SUPPLIER_LIST_REQUEST'
export const GET_SUPPLIER_LIST_SUCCESS = 'GET_SUPPLIER_LIST_SUCCESS'
export const GET_SUPPLIER_LIST_FAIL = 'GET_SUPPLIER_LIST_FAIL'
export const GET_SUPPLIER_REQUEST = 'GET_SUPPLIER_REQUEST'
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS'
export const GET_SUPPLIER_FAIL = 'GET_SUPPLIER_FAIL'
export const GET_MARKS_REQUEST = 'GET_MARKS_REQUEST'
export const GET_MARKS_SUCCESS = 'GET_MARKS_SUCCESS'
export const GET_MARKS_FAIL = 'GET_MARKS_FAIL'
export const GET_SUP_AVAILA_REQUEST = 'GET_SUP_AVAILA_REQUEST'
export const GET_SUP_AVAILA_SUCCESS = 'GET_SUP_AVAILA_SUCCESS'
export const GET_SUP_AVAILA_FAIL = 'GET_SUP_AVAILA_FAIL'

// cars
export const GET_CAR_LIST_REQUEST = 'GET_CAR_LIST_REQUEST'
export const GET_CAR_LIST_SUCCESS = 'GET_CAR_LIST_SUCCESS'
export const GET_CAR_LIST_FAIL = 'GET_CAR_LIST_FAIL'
export const GET_CAR_REQUEST = 'GET_CAR_REQUEST'
export const GET_CAR_SUCCESS = 'GET_CAR_SUCCESS'
export const GET_CAR_FAIL = 'GET_CAR_FAIL'

// attachment types
export const GET_ATTACHMENT_TYPE_LIST_REQUEST = 'GET_ATTACHMENT_TYPE_LIST_REQUEST'
export const GET_ATTACHMENT_TYPE_LIST_SUCCESS = 'GET_ATTACHMENT_TYPE_LIST_SUCCESS'
export const GET_ATTACHMENT_TYPE_LIST_FAIL = 'GET_ATTACHMENT_TYPE_LIST_FAIL'
export const GET_ATTACHMENT_TYPE_REQUEST = 'GET_ATTACHMENT_TYPE_REQUEST'
export const GET_ATTACHMENT_TYPE_SUCCESS = 'GET_ATTACHMENT_TYPE_SUCCESS'
export const GET_ATTACHMENT_TYPE_FAIL = 'GET_ATTACHMENT_TYPE_FAIL'

// companies
export const GET_COMPANY_LIST_REQUEST = 'GET_COMPANY_LIST_REQUEST'
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS'
export const GET_COMPANY_LIST_FAIL = 'GET_COMPANY_LIST_FAIL'
export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL'

// statuses
export const GET_STATUS_LIST_REQUEST = 'GET_STATUS_LIST_REQUEST'
export const GET_STATUS_LIST_SUCCESS = 'GET_STATUS_LIST_SUCCESS'
export const GET_STATUS_LIST_FAIL = 'GET_STATUS_LIST_FAIL'
export const GET_STATUS_REQUEST = 'GET_STATUS_REQUEST'
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS'
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL'

// countries
export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL'

// contracts
export const GET_CONTRACTS_REQUEST = 'GET_CONTRACTS_REQUEST'
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS'
export const GET_CONTRACTS_FAIL = 'GET_CONTRACTS_FAIL'

export const GET_ADJUSTMENT_REQUEST = 'GET_ADJUSTMENT_REQUEST'
export const GET_ADJUSTMENT_SUCCESS = 'GET_ADJUSTMENT_SUCCESS'
export const GET_ADJUSTMENT_FAIL = 'GET_ADJUSTMENT_FAIL'

// OLD
// auth users
export const GET_USER_LIST_REQUEST = 'GET_USERS_REQUEST'
export const GET_USER_LIST_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USER_LIST_FAIL = 'GET_USERS_FAIL'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'

export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_REQUEST'
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS'
export const GET_ROLE_LIST_FAIL = 'GET_ROLE_LIST_FAIL'

export const CREATE_USERS_REQUEST = 'CREATE_USERS_REQUEST'
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS'
export const CREATE_USERS_FAIL = 'CREATE_USERS_FAIL'
export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAIL = 'DELETE_USERS_FAIL'
export const UPDATE_USERS_REQUEST = 'UPDATE_USERS_REQUEST'
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS'
export const UPDATE_USERS_FAIL = 'UPDATE_USERS_FAIL'
export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL'
export const PUT_CURRENT_USER_REQUEST = 'PUT_CURRENT_USER_REQUEST'
export const PUT_CURRENT_USER_SUCCESS = 'PUT_CURRENT_USER_SUCCESS'
export const PUT_CURRENT_USER_FAIL = 'PUT_CURRENT_USER_FAIL'
export const PUT_CHANGE_PASSWORD_REQUEST = 'PUT_CHANGE_PASSWORD_REQUEST'
export const PUT_CHANGE_PASSWORD_SUCCESS = 'PUT_CHANGE_PASSWORD_SUCCESS'
export const PUT_CHANGE_PASSWORD_FAIL = 'PUT_CHANGE_PASSWORD_FAIL'
export const USER_PASSWORD_EDIT_REQUEST = 'USER_PASSWORD_EDIT_REQUEST'
export const USER_PASSWORD_EDIT_SUCCESS = 'USER_PASSWORD_EDIT_SUCCESS'
export const USER_PASSWORD_EDIT_FAIL = 'USER_PASSWORD_EDIT_FAIL'

export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS'
