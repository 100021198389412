import Toast from 'component/toast/Toast'
import {
  LS_LOGGED_USER,
  LS_SESSION_ID_NAME,
  MOCK_API,
  MOCK_PORT_MAPPING,
} from 'helper/configConstants'
import {AUTH_GATEWAY_URL, ENVIRONMENT, GATEWAY_URL} from 'helper/constants'
import browserHistory from 'helper/history'
import React from 'react'
import {toast} from 'react-toastify'
import {Trans} from '@lingui/macro'

/**
 * Redirect user to specific url, can be used outside of component
 * @param {string} route - url to be redirected to
 */
export const redirectTo = (route) => {
  browserHistory.push(route)
}

/**
 * Add service name to gateway url or use mock if set in configConstants
 * @param {string} name - name of service
 */
export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production') {
    if (name === 'auth') {
      // auth has no docker image yet
      return `${AUTH_GATEWAY_URL}/api/${name}`
    }
    if (MOCK_API) {
      return `http://localhost:${MOCK_PORT_MAPPING[name]}`
    }
  }
  return `${GATEWAY_URL}/api/${name}`
}

export const hasUserSessionId = () => {
  return !!getItemFromStorage(LS_SESSION_ID_NAME)
}

export const isLoggedUser = () => {
  return !!getItemFromStorage(LS_LOGGED_USER)
}

export const isAdminUser = () => {
  const user = getItemFromStorage(LS_LOGGED_USER)
  return user?.user?.roles?.some((role) => role.name === 'admin')
}

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options)
}

export const fireErrorToast = (message, options = {autoClose: 15000}) => {
  return toast.error(<Toast message={message} type="error" />, options)
}

export const fireWarningToast = (message, options) => {
  return toast.warning(<Toast message={message} type="warning" />, options)
}

const loopThroughErrors = (message, errors) => {
  if (!!errors?.length) {
    errors?.forEach((err) => {
      fireErrorToast(<>{err?.message || message}</>)
      if (err?.message === 'Session ID not authenticated.') {
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
      }
    })
  } else {
    fireErrorToast(message)
  }
}

/**
 * Function used for basic error handling in catch(err=>globalApiErrorHandler(err))
 * @param error
 */
export const globalApiErrorHandler = (error) => {
  if (error.response) {
    const {status, data} = error.response
    switch (status) {
      case 400: // bad request
        loopThroughErrors(<Trans>Bad request</Trans>, data?.errors)
        return Promise.reject()
      case 401: // unauthorized
        fireErrorToast(<Trans>Unauthorized</Trans>)
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
        break
      case 403: // forbidden
        loopThroughErrors(<Trans>Forbidden</Trans>, data?.errors)
        return Promise.reject()
      case 404: // not found
        loopThroughErrors(<Trans>Not found</Trans>, data?.errors)
        return Promise.reject()
      case 422: // unprocessable entity
        loopThroughErrors(<Trans>Unprocessable entity</Trans>, data?.errors)
        return data?.errors && Promise.reject(data.errors)
      case 500: // internal server error
        loopThroughErrors(<Trans>Internal server error</Trans>, data?.errors)
        return Promise.reject()
      default:
        data?.errors
          ? loopThroughErrors(<Trans>Bad request</Trans>, data.errors)
          : fireErrorToast(<Trans>Unknown server error</Trans>)
        break
    }
  } else if (error.message === 'Network Error' || error.message === 'Server connection issue') {
    fireErrorToast(<Trans>Server connection issue</Trans>)
  } else {
    fireErrorToast(<Trans>Unknown error</Trans>)
  }
  return Promise.reject()
}

export const mapErrorResponseToForm = (err) => {
  let parsedError = {}
  if (Array.isArray(err)) {
    err.forEach((e) => {
      if (e.source.includes('.')) {
        let errorSplit = e.source.split('.')
        if (parsedError.hasOwnProperty(errorSplit[0])) {
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        } else {
          parsedError[errorSplit[0]] = {}
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        }
      } else {
        parsedError[e.source] = e.message
      }
    })
  }
  return parsedError
}

export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const getItemFromStorage = (key, defaultValue = undefined) => {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue
  } catch (e) {
    console.error(e)
    fireErrorToast(<Trans>Local storage could not be parsed</Trans>)
    return defaultValue
  }
}

export const setItemToStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item))
}

export const constructGetOrderBy = (meta) => {
  return meta?.orderBy && meta?.orderDirection ? `${meta?.orderBy}:${meta?.orderDirection}` : null
}

export const getContentDispositionFilename = (headers) => {
  return (
    headers?.['content-disposition']
      ?.split('filename=')?.[1]
      ?.split(';')?.[0]
      ?.replace(/"|'/g, '') || null
  )
}

export const debounce = (id, func, wait = 500) => {
  id && clearTimeout(id)
  return setTimeout(func, wait)
}

export const prepareOptionsForMultiSelect = (optionArray, valueKey = 'id', nameKey = 'name') => {
  if (Array.isArray(optionArray)) {
    const options = {}
    optionArray.forEach((item, index) => {
      // if you provide array of objects take id as option value
      const optionIndex = item?.[valueKey] ? item[valueKey] : index
      const optionName = item?.[nameKey] ? item[nameKey] : item

      options[optionIndex] = optionName
    })
    return options
  } else {
    return {}
  }
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = () => {
      fireErrorToast(<Trans>File reading failed!</Trans>)
      reject()
    }
  })
