// accessing theme which is active in ThemeProvider
const publicLayoutStyle = (theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,

    '& img': {
      position: 'absolute',
      zIndex: 0,
      maxHeight: '100vh',
      maxWidth: '100vw',
      textAlign: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
})

export default publicLayoutStyle
