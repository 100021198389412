import {getKeyByValue, isAdminUser, redirectTo} from 'helper/functions'
import PrivateLayoutTopBar from 'layout/PrivateLayout/PrivateLayoutTopBar'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getCurrentUser} from 'redux/action/authAction'
import {Trans} from '@lingui/macro'
import {Tab, Tabs} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const PrivateLayout = (props) => {
  const {classes, children, getCurrentUser, currentUser, currentUserLoading} = props

  const [tabValue, setTabValue] = useState(0)

  let location = useLocation()

  const tabRouteMap = [
    '/cfs',
    '/supplier',
    '/customer',
    '/car',
    '/fleet',
    '/term',
    '/predefined-term',
    '/type-of-work',
    '/area-of-work',
    '/unit',
    '/region',
    '/attachment',
    '/company',
    '/status',
    '/user',
    '/export-bulk-invoices-by-customer',
    '/export-incoming',
    '/export-outgoing',
    '/bulk-import',
  ]

  const titleRouteMap = [
    <Trans>CFS</Trans>,
    <Trans>Suppliers</Trans>,
    <Trans>Customers</Trans>,
    <Trans>Cars</Trans>,
    <Trans>Fleets</Trans>,
    <Trans>Terms</Trans>,
    <Trans>Predefined terms</Trans>,
    <Trans>Types of work</Trans>,
    <Trans>Areas of work</Trans>,
    <Trans>Units</Trans>,
    <Trans>Regions</Trans>,
    <Trans>Attachment types</Trans>,
    <Trans>Companies</Trans>,
    <Trans>Statuses</Trans>,
    <Trans>Users</Trans>,
    <Trans>Export of bulk outgoing invoices by customer</Trans>,
    <Trans>Export of incoming invoices</Trans>,
    <Trans>Export of outgoing invoices</Trans>,
    <Trans>Bulk import</Trans>,
  ]

  const newTitleRouteMap = [
    <Trans>New CFS</Trans>,
    <Trans>New supplier</Trans>,
    <Trans>New customer</Trans>,
    <Trans>New car</Trans>,
    <Trans>New fleet</Trans>,
    <Trans>New term</Trans>,
    <Trans>New predefined term</Trans>,
    <Trans>New type of work</Trans>,
    <Trans>New area of work</Trans>,
    <Trans>New unit</Trans>,
    <Trans>New region</Trans>,
    <Trans>New attachment</Trans>,
    <Trans>New company</Trans>,
    <Trans>New status</Trans>,
    <Trans>New user</Trans>,
  ]

  const detailTitleRouteMap = [
    <Trans>CFS detail</Trans>,
    <Trans>Supplier detail</Trans>,
    <Trans>Customer detail</Trans>,
    <Trans>Car detail</Trans>,
    <Trans>Fleet detail</Trans>,
    <Trans>Term detail</Trans>,
    <Trans>Predefined term detail</Trans>,
    <Trans>Type of work detail</Trans>,
    <Trans>Area of work detail</Trans>,
    <Trans>Unit detail</Trans>,
    <Trans>Region detail</Trans>,
    <Trans>Attachment detail</Trans>,
    <Trans>Company detail</Trans>,
    <Trans>Status detail</Trans>,
    <Trans>User detail</Trans>,
  ]

  const getTitle = () => {
    const detailId = location.pathname?.split('/')?.[2]
    if (detailId === 'new') return newTitleRouteMap[tabValue]
    if (!isNaN(Number(detailId))) return detailTitleRouteMap[tabValue]
    return titleRouteMap[tabValue]
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
    redirectTo(tabRouteMap[newValue])
  }

  // Open correct tab based on route
  useEffect(() => {
    let rootRoute = location.pathname?.split('/')?.[1]
    setTabValue(tabRouteMap.findIndex((t) => t === `/${rootRoute}`))
  }, [location])

  useEffect(() => {
    getCurrentUser().catch(() => {})
  }, [])

  const isAdmin = isAdminUser()

  return (
    <div className={classes.root}>
      <PrivateLayoutTopBar loading={currentUserLoading} currentUser={currentUser} />

      <main className={classes.content}>
        <div className={classes.pageTitle}>{getTitle()}</div>
        <div className={classes.scrollableTabsWidth}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            onChange={handleChangeTab}
            className={classes.customTabs}
          >
            <Tab label={<Trans>Cfs</Trans>} disableRipple={true} />
            <Tab label={<Trans>Suppliers</Trans>} disableRipple={true} />
            <Tab label={<Trans>Customers</Trans>} disableRipple={true} />
            <Tab label={<Trans>Cars</Trans>} disableRipple={true} />
            <Tab label={<Trans>Fleets</Trans>} disableRipple={true} />
            <Tab label={<Trans>Terms</Trans>} disableRipple={true} />
            <Tab label={<Trans>Predefined terms</Trans>} disableRipple={true} />
            <Tab label={<Trans>Types of work</Trans>} disableRipple={true} />
            <Tab label={<Trans>Areas of work</Trans>} disableRipple={true} />
            <Tab label={<Trans>Units</Trans>} disableRipple={true} />
            <Tab label={<Trans>Regions</Trans>} disableRipple={true} />
            <Tab label={<Trans>Attachment types</Trans>} disableRipple={true} />
            <Tab label={<Trans>Companies</Trans>} disableRipple={true} />
            <Tab label={<Trans>Statuses</Trans>} disableRipple={true} />
            {isAdmin && <Tab label={<Trans>Users</Trans>} disableRipple={true} />}
            {isAdmin && (
              <Tab
                label={<Trans>Export of bulk outgoing invoices by customer</Trans>}
                disableRipple={true}
              />
            )}
            {isAdmin && (
              <Tab label={<Trans>Export of incoming invoices</Trans>} disableRipple={true} />
            )}
            {isAdmin && (
              <Tab label={<Trans>Export of outgoing invoices</Trans>} disableRipple={true} />
            )}
            {isAdmin && <Tab label={<Trans>Bulk import</Trans>} disableRipple={true} />}
          </Tabs>
        </div>

        {children}
      </main>
    </div>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  getCurrentUser: PropTypes.func,
  currentUser: PropTypes.object,
  currentUserLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCurrentUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      currentUser: store.auth.currentUser,
      currentUserLoading: store.auth.currentUserLoading,
    }
  }, mapDispatchToProps)
)(PrivateLayout)
