import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_ATTACHMENT_TYPE_FAIL,
  GET_ATTACHMENT_TYPE_LIST_FAIL,
  GET_ATTACHMENT_TYPE_LIST_REQUEST,
  GET_ATTACHMENT_TYPE_LIST_SUCCESS,
  GET_ATTACHMENT_TYPE_REQUEST,
  GET_ATTACHMENT_TYPE_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getAttachmentTypeList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_ATTACHMENT_TYPE_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.attachmentTypes + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_ATTACHMENT_TYPE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ATTACHMENT_TYPE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getAttachmentType = (id) => {
  return (dispatch) => {
    dispatch({type: GET_ATTACHMENT_TYPE_REQUEST})
    return httpClient
      .get(endpoints.attachmentTypes + '/' + id)
      .then((res) => {
        dispatch({type: GET_ATTACHMENT_TYPE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ATTACHMENT_TYPE_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createAttachmentType = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.attachmentTypes, data)
      .then((res) => {
        fireSuccessToast(<Trans>New attachment type created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchAttachmentType = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.attachmentTypes + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Attachment type updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
