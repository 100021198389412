import CustomerContractAdjustmentTemplates from 'component/CustomersPage/CustomerContractAdjustmentTemplates'
import detailPageStyle from 'component/detailPageStyle'
import CheckBoxInput from 'component/field/CheckBoxInput'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import fieldStyle from 'component/field/fieldStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Loader from 'component/material/Loader'
import PrimaryButton from 'component/material/PrimaryButton'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {
  createCustomer,
  getCustomer,
  getCustomerContractAdjustmentTemplates,
  patchCustomer,
} from 'redux/action/customersAction'
import {getProviderList} from 'redux/action/providersAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomerDetail = (props) => {
  const {
    classes,
    customer,
    customerLoading,
    getCustomer,
    getProviderList,
    patchCustomer,
    providerList,
    providerListLoading,
    createCustomer,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
      enabled: !!values.enabled,
      invoice_bulk: !!values.invoice_bulk,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createCustomer(preparedValues)
        .then((res) => {
          redirectTo('/customer')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchCustomer(id, preparedValues)
        .then((res) => {
          redirectTo('/customer')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    if (id !== 'new') {
      return customer
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getCustomer(id).catch(() => {})
      }
      getProviderList(1000, 0).catch(() => {})
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      {/*{customerLoading && <Loader />}*/}
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {({submitting, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
              <GridContainer item xs={5}>
                <BoxFullWidth p={2} className={classes.formTitle}>
                  <Trans>General information</Trans>
                </BoxFullWidth>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="name"
                    label={<Trans>Name</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="ic"
                    label={<Trans>IC</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="dic"
                    label={<Trans>DIC</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="street"
                    label={<Trans>Street</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="building_no"
                    label={<Trans>Building No.</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="city"
                    label={<Trans>City</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="postal_zone"
                    label={<Trans>Postal zone</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="account_no"
                    label={<Trans>Account no.:</Trans>}
                    component={TextInput}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                xs={1}
              />

              <GridContainer
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                xs={5}
              >
                <BoxFullWidth p={2} className={classes.formTitle}>
                  <Trans>System information</Trans>
                </BoxFullWidth>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="provider_id"
                    label={<Trans>Provider</Trans>}
                    component={SelectInput}
                    options={providerList}
                    loading={providerListLoading}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="cfs"
                    label={<Trans>Customer ID</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="ean"
                    label={<Trans>EAN</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="eos_id"
                    label={<Trans>EOS Id</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="invoice_due"
                    label={<Trans>Invoice due</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="invoice_bulk"
                    label={<Trans>Invoice bulk</Trans>}
                    component={CheckBoxInput}
                    type={'checkbox'}
                  />
                </GridItem>
                <GridItem container xs={12}>
                  <Field
                    disabled={submitting}
                    name="enabled"
                    label={<Trans>Enabled</Trans>}
                    component={CheckBoxInput}
                    type={'checkbox'}
                  />
                </GridItem>
              </GridContainer>

              <CustomerContractAdjustmentTemplates submittion={submitting} id={id} />
            </GridContainer>

            <GridItem xs={12} container justifyContent="flex-end">
              <Box pt={5}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  size="small"
                  type="submit"
                  textPadding={5}
                  text={<Trans>Save</Trans>}
                />
              </Box>
            </GridItem>
          </form>
        )}
      </Form>
    </div>
  )
}

CustomerDetail.propTypes = {
  classes: PropTypes.object,
  customer: PropTypes.object,
  customerLoading: PropTypes.bool,
  getCustomer: PropTypes.func,
  getProviderList: PropTypes.func,
  patchCustomer: PropTypes.func,
  providerList: PropTypes.array,
  providerListLoading: PropTypes.bool,
  createCustomer: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomer,
      getProviderList,
      patchCustomer,
      createCustomer,
      getCustomerContractAdjustmentTemplates,
    },
    dispatch
  )
}

export default compose(
  withStyles((theme) => ({
    ...detailPageStyle(theme),
    ...fieldStyle(theme),
  })),
  connect((store) => {
    return {
      customer: store.customer.customer,
      customerLoading: store.customer.customerLoading,
      providerList: store.provider.providerList,
      providerListLoading: store.provider.providerListLoading,
    }
  }, mapDispatchToProps)
)(CustomerDetail)
