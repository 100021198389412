import {useState} from 'react'

const useTablePage = (initialValue = 0) => {
  const [page, setPage] = useState(initialValue)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  return [page, handleChangePage]
}

export default useTablePage
