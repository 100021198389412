import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import i18nProvider from 'helper/i18n'
import React from 'react'
import {t} from '@lingui/macro'
import {Checkbox, TextField} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SearchMultiselectInput = (props) => {
  const {classes, options, onChange, loading, label, filters = true, name = 'multiselect'} = props

  return (
    <div className={cx(classes.fullWidth, !filters && classes.inLineField)}>
      {label && (
        <div className={cx(classes.fieldLabel, filters && classes.filtersFieldLabel)}>{label}:</div>
      )}
      <Autocomplete
        name={name}
        className={classes.searchMultiselect}
        multiple
        size={'small'}
        onChange={onChange}
        options={options}
        disableCloseOnSelect={true}
        getOptionLabel={(option) => option.customer_code}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.customer_code}
              size="small"
              {...getTagProps({index})}
              className={classes.multiSelectChip}
            />
          ))
        }
        renderOption={(option, {selected}) => (
          <>
            <Checkbox size={'small'} checked={selected} />
            <ListItemText primary={option.customer_code} />
          </>
        )}
        clearText={i18nProvider._(t`Clean input`)}
        closeText={i18nProvider._(t`Close select`)}
        loadingText={i18nProvider._(t`Loading options...`)}
        noOptionsText={i18nProvider._(t`No options found`)}
        openText={i18nProvider._(t`Open select`)}
        loading={loading}
        renderInput={(params) => <TextField {...params} size={'small'} variant="outlined" />}
      />
    </div>
  )
}

export default withStyles(fieldStyle)(SearchMultiselectInput)
