import {
  GET_AREA_OF_WORK_FAIL,
  GET_AREA_OF_WORK_LIST_FAIL,
  GET_AREA_OF_WORK_LIST_REQUEST,
  GET_AREA_OF_WORK_LIST_SUCCESS,
  GET_AREA_OF_WORK_REQUEST,
  GET_AREA_OF_WORK_SUCCESS,
} from 'redux/actionType'

const initState = {
  areaOfWorkList: [],
  areaOfWorkListLoading: false,
  areaOfWorkListMeta: {},
  areaOfWork: {},
  areaOfWorkLoading: false,
}

const areaOfWorkReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_AREA_OF_WORK_LIST_REQUEST: {
      return {
        ...state,
        areaOfWorkListLoading: true,
      }
    }
    case GET_AREA_OF_WORK_LIST_SUCCESS: {
      return {
        ...state,
        areaOfWorkListLoading: false,
        areaOfWorkList: action.data?.objects,
        areaOfWorkListMeta: action.data?.meta,
      }
    }
    case GET_AREA_OF_WORK_LIST_FAIL: {
      return {
        ...state,
        areaOfWorkListLoading: false,
      }
    }
    case GET_AREA_OF_WORK_REQUEST: {
      return {
        ...state,
        areaOfWork: {},
        areaOfWorkLoading: true,
      }
    }
    case GET_AREA_OF_WORK_SUCCESS: {
      return {
        ...state,
        areaOfWork: action.data,
        areaOfWorkLoading: false,
      }
    }
    case GET_AREA_OF_WORK_FAIL: {
      return {
        ...state,
        areaOfWorkLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default areaOfWorkReducer
