import componentStyle from 'component/componentStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getAreaOfWorkList} from 'redux/action/areasOfWorkAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

let searchInterval = null

const AreaOfWorksPage = (props) => {
  const {
    classes,
    rowsPerPage,
    getAreaOfWorkList,
    areaOfWorkList,
    areaOfWorkListLoading,
    areaOfWorkListMeta,
  } = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'description', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [searchValue, setSearchValue] = useState(null)

  const openDetail = (row) => () => {
    redirectTo('/area-of-work/' + row.id)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'description',
      sortKey: 'description',
      label: <Trans>Description</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/area-of-work/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      handleChangePage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getAreaOfWorkList(rowsPerPage, page * rowsPerPage, tableSort, searchValue).catch(() => {})
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={areaOfWorkListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div>
          <CustomTooltip title={<Trans>New area of work</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Add</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>
      <div className={classes.overFlowAuto}>
        <Table
          data={areaOfWorkList}
          columns={columns}
          loading={areaOfWorkListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          meta={areaOfWorkListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

AreaOfWorksPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getAreaOfWorkList: PropTypes.func,
  areaOfWorkList: PropTypes.array,
  areaOfWorkListLoading: PropTypes.bool,
  areaOfWorkListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAreaOfWorkList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      areaOfWorkList: store.areaOfWork.areaOfWorkList,
      areaOfWorkListLoading: store.areaOfWork.areaOfWorkListLoading,
      areaOfWorkListMeta: store.areaOfWork.areaOfWorkListMeta,
    }
  }, mapDispatchToProps)
)(AreaOfWorksPage)
