import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const Loader = (props) => {
  const {classes, className, wrapper = false} = props
  return wrapper ? (
    <div className={classes.loaderWrapper}>
      <div className={cx(classes.loader, className)} />
    </div>
  ) : (
    <div className={cx(classes.loader, className)} />
  )
}

Loader.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapper: PropTypes.bool,
}

export default withStyles(materialStyle)(Loader)
