import {DB_DATE_FORMAT} from 'helper/constants'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import moment from 'moment'
import React from 'react'
import {
  GET_CFS_CHANGELOG_FAIL,
  GET_CFS_CHANGELOG_REQUEST,
  GET_CFS_CHANGELOG_SUCCESS,
  GET_CFS_FAIL,
  GET_CFS_LIST_FAIL,
  GET_CFS_LIST_REQUEST,
  GET_CFS_LIST_SUCCESS,
  GET_CFS_REQUEST,
  GET_CFS_STATUS_FAIL,
  GET_CFS_STATUS_REQUEST,
  GET_CFS_STATUS_SUCCESS,
  GET_CFS_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getCfsList = (limit = 1000, offset = 0, meta = {}, filters = {}, search) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.invoices + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        tax_document_no: filters?.tax_document_no ? filters.tax_document_no : null,
        customer_id: filters?.customer_id ? filters.customer_id : null,
        supplier_id: filters?.supplier_id ? filters.supplier_id : null,
        rz: filters?.rz ? filters.rz : null,
        date_from: filters?.date_from ? moment(filters.date_from).format(DB_DATE_FORMAT) : null,
        date_to: filters?.date_to ? moment(filters.date_to).format(DB_DATE_FORMAT) : null,
        status__in: filters?.status ? `${[...filters.status]}` : null,
      })
      .then((res) => {
        dispatch({type: GET_CFS_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCfsListBulkExportable = (limit = 1000, offset = 0, meta = {}, filters = {}) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_LIST_REQUEST})
    return httpClient
      .get(endpoints.invoicesBulkExportable, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        customer_id: filters?.customer_id ? filters.customer_id : null,
      })
      .then((res) => {
        dispatch({type: GET_CFS_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCfs = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_REQUEST})
    return httpClient
      .get(endpoints.invoices + '/' + id)
      .then((res) => {
        dispatch({type: GET_CFS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createCfs = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.invoices, data)
      .then((res) => {
        fireSuccessToast(<Trans>New invoice created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchCfs = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.invoices + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Invoice updated.</Trans>)
        // save data to cfs, otherwise the data flicker between PATCH and following GET
        dispatch({type: GET_CFS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getInvoiceStatuses = (limit = 1000, offset = 0, meta = {}) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_STATUS_REQUEST})
    return httpClient
      .get(endpoints.invoiceStatuses, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CFS_STATUS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_STATUS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getInvoiceChangeLog = (id, limit = 1000, offset = 0, meta = {}) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_CHANGELOG_REQUEST})
    return httpClient
      .get(`${endpoints.invoices}/${id}/change-logs`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CFS_CHANGELOG_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_CHANGELOG_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const bulkExportByCustomer = (customerId) => {
  return () => {
    return httpClient
      .post(endpoints.exportBulkInvoices, {customer_id: customerId})
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const invoiceExportByType = (type) => {
  return () => {
    return httpClient
      .post(endpoints.exportInvoices, {type: type})
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const postBulkImport = (data) => {
  return () => {
    return httpClient
      .post(endpoints.bulkImport, data)
      .then((res) => {
        fireSuccessToast(<Trans>Bulk import succeeded.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          fireErrorToast(<Trans>Bulk import failed.</Trans>)
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
