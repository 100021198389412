import cx from 'classnames'
import componentStyle from 'component/componentStyle'
import SelectInput from 'component/field/SelectInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {isLoggedUser, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {bulkExportByCustomer, getCfsListBulkExportable} from 'redux/action/cfsAction'
import {getCustomerListBulkExportable} from 'redux/action/customersAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import {CircularProgress} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

const ExportBulkInvoicesByCustomerPage = (props) => {
  const {
    classes,
    rowsPerPage,
    customerList,
    customerListLoading,
    getCustomerListBulkExportable,
    getCfsListBulkExportable,
    cfsList,
    cfsListLoading,
    cfsListMeta,
    bulkExportByCustomer,
  } = props

  const [customerFilter, setCustomerFilter] = useState(null)
  const [exportInProgress, setExportInProgress] = useState(false)

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'date_issue', orderDirection: 'DESC'})
  const [page, handleChangePage] = useTablePage()

  const openDetail = (row) => () => {
    redirectTo('/cfs/' + row.id)
  }

  const columns = [
    {
      name: 'supplier_elit_no',
      sortKey: 'supplier_elit_no',
      label: <Trans>Supplier Elit No.</Trans>,
    },
    {
      name: 'customer_name',
      sortKey: 'customer_name',
      label: <Trans>Customer</Trans>,
    },
    {
      name: 'customer_cfs',
      sortKey: 'customer_cfs',
      label: <Trans>Customer CFS only</Trans>,
    },
    {
      name: 'tax_document_no',
      sortKey: 'tax_document_no',
      align: 'right',
      label: <Trans>Invoice no.</Trans>,
    },
    {
      name: 'version',
      sortKey: 'version',
      align: 'right',
      label: <Trans>Version</Trans>,
    },
    {
      name: 'order_no',
      sortKey: 'order_no',
      align: 'right',
      label: <Trans>Order No.</Trans>,
    },
    {
      name: 'rz',
      sortKey: 'rz',
      label: <Trans>RZ</Trans>,
    },
    {
      name: 'date_issue',
      sortKey: 'date_issue',
      align: 'right',
      label: <Trans>Date issue</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const onFilterChange = (e) => {
    const value = e.target?.value

    setCustomerFilter(value)
  }

  const handleSubmit = () => {
    // empty submit method, there is no need for form submit, filtering is on change
    // but still it is easier to implement fields with in the form
  }

  const handleExport = () => {
    setExportInProgress(true)
    return bulkExportByCustomer(customerFilter)
      .catch(() => {})
      .finally(() => setExportInProgress(false))
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getCfsListBulkExportable(rowsPerPage, page * rowsPerPage, tableSort).catch(() => {})
    }
  }, [page, rowsPerPage, tableSort])

  useEffect(() => {
    if (isLoggedUser()) {
      getCustomerListBulkExportable(1000, 0, {orderBy: 'name', orderDirection: 'ASC'}).catch(
        () => {}
      )
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <>
      <Accordion
        className={cx(classes.tableFilters, classes.tabsCursorDefault)}
        elevation={0}
        expanded={true}
        defaultExpanded={true}
      >
        <AccordionSummary expandIcon={null}>
          <BoxFullWidth className={classes.formTitle}>
            <Trans>Filters</Trans>
          </BoxFullWidth>
        </AccordionSummary>
        <AccordionDetails>
          <Form onSubmit={handleSubmit}>
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <GridContainer
                  spacing={4}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <GridItem container xs={12} sm={6} md={4} lg={3}>
                    <Field
                      disabled={formProps.submitting || customerListLoading}
                      name="customer_id"
                      label={<Trans>Customer</Trans>}
                      component={SelectInput}
                      options={customerList?.map((customer) => ({
                        name: customer.name,
                        value: customer.id,
                      }))}
                      loading={customerListLoading}
                      onChange={onFilterChange}
                      emptyOption={true}
                      showHelperText={false}
                      filters={true}
                    />
                  </GridItem>
                  <GridItem container xs={12} sm={6} md={4} lg={3} className={classes.alignSelfEnd}>
                    <PrimaryButton
                      fullWidth={false}
                      textPadding={0}
                      text={
                        exportInProgress ? (
                          <GridContainer
                            direction={'row'}
                            spacing={2}
                            container
                            alignItems={'center'}
                          >
                            <GridItem xs={false} className={classes.flexAlignCenter}>
                              <CircularProgress size={14} />
                            </GridItem>
                            <GridItem xs={true}>
                              <Trans>Export in progress</Trans>
                            </GridItem>
                          </GridContainer>
                        ) : (
                          <Trans>Export</Trans>
                        )
                      }
                      disabled={!customerFilter || exportInProgress}
                      onClick={handleExport}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Form>
        </AccordionDetails>
      </Accordion>
      <BoxFullWidth>
        <div className={classes.overFlowAuto}>
          <Table
            data={cfsList}
            columns={columns}
            loading={cfsListLoading}
            onTableColumnSort={handleTableSort}
            tableSort={tableSort}
            page={page}
            meta={cfsListMeta}
            onChangePage={handleChangePage}
          />
        </div>
      </BoxFullWidth>
    </>
  )
}

ExportBulkInvoicesByCustomerPage.propTypes = {
  classes: PropTypes.object,
  customerList: PropTypes.array,
  customerListLoading: PropTypes.bool,
  getCustomerListBulkExportable: PropTypes.func,
  getCfsListBulkExportable: PropTypes.func,
  rowsPerPage: PropTypes.number,
  cfsList: PropTypes.array,
  cfsListLoading: PropTypes.bool,
  cfsListMeta: PropTypes.object,
  bulkExportByCustomer: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerListBulkExportable,
      getCfsListBulkExportable,
      bulkExportByCustomer,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      customerList: store.customer.customerList,
      customerListLoading: store.customer.customerListLoading,
      rowsPerPage: store.globalSettings.rowsPerPage,
      cfsList: store.cfs.cfsList,
      cfsListLoading: store.cfs.cfsListLoading,
      cfsListMeta: store.cfs.cfsListMeta,
    }
  }, mapDispatchToProps)
)(ExportBulkInvoicesByCustomerPage)
