import {combineReducers} from 'redux'
import accountReducer from 'redux/reducer/accountsReducer'
import areaOfWorkReducer from 'redux/reducer/areasOfWorkReducer'
import attachmentTypeReducer from 'redux/reducer/attachmentTypesReducer'
import authReducer from 'redux/reducer/authReducer'
import branchReducer from 'redux/reducer/branchesReducer'
import carReducer from 'redux/reducer/carsReducer'
import cfsReducer from 'redux/reducer/cfsReducer'
import companyReducer from 'redux/reducer/companiesReducer'
import countryReducer from 'redux/reducer/countriesReducer'
import customerReducer from 'redux/reducer/customersReducer'
import fleetReducer from 'redux/reducer/fleetsReducer'
import globalSettingsReducer from 'redux/reducer/globalSettingsReducer'
import predefinedTermReducer from 'redux/reducer/predefinedTermsReducer'
import providerReducer from 'redux/reducer/providersReducer'
import regionReducer from 'redux/reducer/regionsReducer'
import statusReducer from 'redux/reducer/statusesReducer'
import supplierReducer from 'redux/reducer/suppliersReducer'
import termReducer from 'redux/reducer/termsReducer'
import typeOfWorkReducer from 'redux/reducer/typesOfWorkReducer'
import unitReducer from 'redux/reducer/unitsReducer'
import userReducer from 'redux/reducer/userReducer'

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  globalSettings: globalSettingsReducer,
  cfs: cfsReducer,
  customer: customerReducer,
  fleet: fleetReducer,
  term: termReducer,
  predefinedTerm: predefinedTermReducer,
  typeOfWork: typeOfWorkReducer,
  areaOfWork: areaOfWorkReducer,
  unit: unitReducer,
  region: regionReducer,
  supplier: supplierReducer,
  car: carReducer,
  attachment: attachmentTypeReducer,
  company: companyReducer,
  status: statusReducer,
  provider: providerReducer,
  account: accountReducer,
  branch: branchReducer,
  country: countryReducer,
})
