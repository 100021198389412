const buttonStyle = (theme) => ({
  successButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover, &:active': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  warningButton: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover, &:active': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  errorButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover, &:active': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
})

export default buttonStyle
