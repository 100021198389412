import detailPageStyle from 'component/detailPageStyle'
import MultiSelectInput from 'component/field/MultiSelectInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {EDI_RESET_PSW_URL, INVOICES_RESET_PSW_URL} from 'helper/constants'
import {
  isLoggedUser,
  mapErrorResponseToForm,
  prepareOptionsForMultiSelect,
  redirectTo,
} from 'helper/functions'
import {validateEmail} from 'helper/validations'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {welcomeUser} from 'redux/action/authAction'
import {createUser, getRoles, getUser, patchUser} from 'redux/action/userAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const UserDetail = (props) => {
  const {
    classes,
    getUser,
    user,
    userLoading,
    patchUser,
    getRoles,
    roleList,
    roleListLoading,
    createUser,
    welcomeUser,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    let valuesWithoutRoles = values
    delete valuesWithoutRoles?.roles

    return {
      ...valuesWithoutRoles,
      name: valuesWithoutRoles?.name ? valuesWithoutRoles.name : '',
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)

    if (id === 'new') {
      return createUser(preparedValues)
        .then((res) => {
          const pswUrl =
            values?.role_ids?.length === 1 && values?.role_ids?.[0] === 6
              ? INVOICES_RESET_PSW_URL
              : EDI_RESET_PSW_URL

          welcomeUser(res.id, {reset_password_url: pswUrl})
          redirectTo('/user')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchUser(id, preparedValues)
        .then((res) => {
          redirectTo('/user')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...user,
      role_ids: user?.roles?.map((role) => role.id),
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getUser(id).catch(() => {})
      }
      getRoles().catch(() => {})
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form onSubmit={handleSubmit} initialValues={id !== 'new' ? getInitialValues() : {}}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="name"
                      label={<Trans>Name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="username"
                      label={<Trans>Username</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="email"
                      label={<Trans>E-Mail</Trans>}
                      component={TextInput}
                      validate={validateEmail}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="role_ids"
                      label={<Trans>Role</Trans>}
                      component={MultiSelectInput}
                      options={roleList && prepareOptionsForMultiSelect(roleList)}
                      loading={roleListLoading}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

UserDetail.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  userLoading: PropTypes.bool,
  getUser: PropTypes.func,
  patchUser: PropTypes.func,
  getRoles: PropTypes.func,
  createUser: PropTypes.func,
  roleList: PropTypes.array,
  roleListLoading: PropTypes.bool,
  welcomeUser: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUser,
      patchUser,
      createUser,
      getRoles,
      welcomeUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      user: store.user.user,
      userLoading: store.user.userLoading,
      roleList: store.user.roleList,
      roleListLoading: store.user.roleListLoading,
    }
  }, mapDispatchToProps)
)(UserDetail)
