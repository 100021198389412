import componentStyle from 'component/componentStyle'
import DialogWindow from 'component/material/DialogWindow'
import Table from 'component/material/table/Table'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getFleetList} from 'redux/action/fleetsAction'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

const TemplateFleetsDialog = (props) => {
  const {
    open,
    onClose,
    data,
    classes,
    fleetList,
    fleetListLoading,
    fleetListMeta,
    getFleetList,
    rowsPerPage,
  } = props

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'customer', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'customer',
      sortKey: 'customer',
      label: <Trans>Customer</Trans>,
    },
    {
      name: 'code',
      sortKey: 'code',
      label: <Trans>Code</Trans>,
    },
    {
      name: 'default_fleet',
      sortKey: 'default_fleet',
      label: <Trans>Default</Trans>,
      render: (val) => (val ? <Trans>default</Trans> : ''),
    },
    {
      name: 'enabled',
      sortKey: 'enabled',
      label: <Trans>Enabled</Trans>,
      render: (val) => (val ? '' : <Trans>disabled</Trans>),
    },

    {
      name: 'case_with_painting_allowed',
      sortKey: 'case_with_painting_allowed',
      label: <Trans>Case with painting allowed</Trans>,
      render: (val) => (val ? '' : <Trans>disallowed</Trans>),
    },
  ]

  useEffect(() => {
    if (open && data?.id) {
      getFleetList(rowsPerPage, page * rowsPerPage, tableSort, null, {
        contract_adjustment_template_id: data.id,
      }).catch(() => {})
    }
  }, [open, data, page, rowsPerPage, tableSort])

  return (
    <DialogWindow
      title={<Trans>Fleets using {data.name}</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'md'}
    >
      <div className={classes.overFlowAuto}>
        <Table
          data={fleetList}
          columns={columns}
          loading={fleetListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          meta={fleetListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </DialogWindow>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFleetList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      fleetList: store.fleet.fleetList,
      fleetListMeta: store.fleet.fleetListMeta,
      fleetListLoading: store.fleet.fleetListLoading,
    }
  }, mapDispatchToProps)
)(TemplateFleetsDialog)
