import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import download from 'downloadjs'
import {DATE_FORMAT} from 'helper/constants'
import {getContentDispositionFilename} from 'helper/functions'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {downloadArchive, downloadSupContract, getSupContracts} from 'redux/action/suppliersAction'
import {Trans} from '@lingui/macro'
import {Link} from '@material-ui/core'

const ArchiveContractDialog = (props) => {
  const {
    id,
    classes,
    open,
    onClose,
    getSupContracts,
    contractsLoading,
    contractsData,
    contractsMeta,
    rowsPerPage,
    downloadSupContract,
    downloadArchive,
  } = props

  const [page, setPage] = useState(0)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleDownloadContract = (contractId) => {
    downloadSupContract(id, contractId)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
  }

  const handleDownloadAll = () => {
    downloadArchive(id)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/zip')
      })
      .catch(() => {})
  }

  const archiveColumns = [
    {
      name: 'file',
      label: <Trans>Name</Trans>,
      render: (val, row) => (
        <Link
          className={classes.cursorPointer}
          onClick={() => row.id && handleDownloadContract(row.id)}
        >
          {val.filename}
        </Link>
      ),
    },
    {
      name: 'signed_at',
      label: <Trans>Signed at</Trans>,
      render: (val) => <span>{val && moment(val).format(DATE_FORMAT)}</span>,
    },
    {
      name: 'created_at',
      label: <Trans>Created at</Trans>,
      render: (val) => <span>{val && moment(val).format(DATE_FORMAT)}</span>,
    },
  ]

  useEffect(() => {
    if (open) {
      getSupContracts(id, true, rowsPerPage, page * rowsPerPage).catch(() => {})
    }
  }, [open, page, rowsPerPage])

  return (
    <DialogWindow title={<Trans>Archive</Trans>} open={open} onClose={onClose} maxWidth={'md'}>
      <BoxFullWidth pb={4} align={'right'}>
        <PrimaryButton
          onClick={handleDownloadAll}
          fullWidth={false}
          size="small"
          text={<Trans>Download all contracts</Trans>}
        />
      </BoxFullWidth>
      <Table
        data={contractsData}
        columns={archiveColumns}
        loading={contractsLoading}
        meta={contractsMeta}
        page={page}
        onChangePage={handleChangePage}
      />
    </DialogWindow>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSupContracts,
      downloadSupContract,
      downloadArchive,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      contractsLoading: store.supplier.contractsLoading,
      contractsData: store.supplier.contractsData,
      contractsMeta: store.supplier.contractsMeta,
    }
  }, mapDispatchToProps)
)(ArchiveContractDialog)
