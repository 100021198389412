import detailPageStyle from 'component/detailPageStyle'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {createRegion, getRegion, patchRegion} from 'redux/action/regionsAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const RegionDetail = (props) => {
  const {classes, patchRegion, createRegion, getRegion, region, regionLoading} = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createRegion(preparedValues)
        .then((res) => {
          redirectTo('/region')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchRegion(id, preparedValues)
        .then((res) => {
          redirectTo('/region')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...region,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getRegion(id).catch(() => {})
      }
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form onSubmit={handleSubmit} initialValues={id !== 'new' ? getInitialValues() : {}}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="code"
                      label={<Trans>Code</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

RegionDetail.propTypes = {
  classes: PropTypes.object,
  getRegion: PropTypes.func,
  patchRegion: PropTypes.func,
  region: PropTypes.object,
  regionLoading: PropTypes.bool,
  createRegion: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRegion,
      patchRegion,
      createRegion,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      region: store.region.region,
      regionLoading: store.region.regionLoading,
    }
  }, mapDispatchToProps)
)(RegionDetail)
