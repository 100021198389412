import ContractTemplateDialog from 'component/CustomersPage/ContractTemplateDialog'
import TemplateFleetsDialog from 'component/CustomersPage/TemplateFleetsDialog'
import detailPageStyle from 'component/detailPageStyle'
import fieldStyle from 'component/field/fieldStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import download from 'downloadjs'
import {getContentDispositionFilename} from 'helper/functions'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  downloadContractAdjustmentTemplatePreview,
  getCustomerContractAdjustmentTemplates,
} from 'redux/action/customersAction'
import {Trans} from '@lingui/macro'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {Add} from '@material-ui/icons'

const CustomerContractAdjustmentTemplates = (props) => {
  const {
    classes,
    submitting,
    id,
    customerContractTemplate,
    getCustomerContractAdjustmentTemplates,
    downloadContractAdjustmentTemplatePreview,
  } = props

  const [dialogData, setDialogData] = useState({visible: false, data: {}})
  const [fleetDialogData, setFleetDialogData] = useState({visible: false, data: {}})

  const [previewData, setPreviewData] = useState({loading: false, data: {}})

  const handleOpenDialog = (row) => {
    setDialogData({visible: true, data: row})
  }
  const handleCloseDialog = () => {
    setDialogData({visible: false, data: {}})
  }
  const handleOpenFleetDialog = (row) => {
    setFleetDialogData({visible: true, data: row})
  }
  const handleCloseFleetDialog = () => {
    setFleetDialogData({visible: false, data: {}})
  }

  const handleOpenPreview = (contractId) => {
    setPreviewData({loading: true, data: contractId})
    downloadContractAdjustmentTemplatePreview(id, contractId)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
      .finally(() => setPreviewData({loading: false, data: {}}))
  }

  const handleGetCustomerContractAdjustmentTemplate = () => {
    getCustomerContractAdjustmentTemplates(id).catch(() => {})
  }

  useEffect(() => {
    handleGetCustomerContractAdjustmentTemplate()
  }, [])

  return (
    <GridContainer item direction="row" justifyContent="flex-start" alignItems="center">
      <BoxFullWidth p={2} pt={6} className={classes.formTitle}>
        <Trans>Contract adjustment templates</Trans>
      </BoxFullWidth>

      {customerContractTemplate.map((template) => (
        <BoxFullWidth py={2} key={template.id}>
          <GridContainer direction={'row'} spacing={2}>
            <GridItem xs={4}>
              <Typography className={classes.fieldLabel}>{template.name}</Typography>
            </GridItem>
            <GridItem xs={6} container spacing={2}>
              <GridItem xs={false}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  size="small"
                  textPadding={2}
                  text={<Trans>Fleets</Trans>}
                  onClick={() => handleOpenFleetDialog({id: template.id, name: template.name})}
                />
              </GridItem>
              <GridItem xs={false}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  size="small"
                  textPadding={2}
                  text={<Trans>Edit</Trans>}
                  onClick={() => handleOpenDialog(template)}
                />
              </GridItem>
              <GridItem xs={false}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting || (previewData.data === template.id && previewData.loading)}
                  size="small"
                  textPadding={2}
                  text={
                    previewData.data === template.id ? (
                      <Trans>Downloading...</Trans>
                    ) : (
                      <Trans>Download preview</Trans>
                    )
                  }
                  onClick={() => handleOpenPreview(template.id)}
                />
              </GridItem>
            </GridItem>
          </GridContainer>
        </BoxFullWidth>
      ))}

      <BoxFullWidth py={2}>
        <GridContainer direction={'row'} spacing={2}>
          <GridItem xs={4}>
            <Typography className={classes.fieldLabel}>
              <Trans>New template</Trans>
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <PrimaryButton
              fullWidth={false}
              disabled={submitting}
              size="small"
              textPadding={0}
              text={<Trans>Create</Trans>}
              startIcon={<Add />}
              onClick={() => handleOpenDialog(null)}
            />
          </GridItem>
        </GridContainer>
      </BoxFullWidth>

      <ContractTemplateDialog
        id={id}
        open={dialogData.visible}
        data={dialogData.data}
        onClose={handleCloseDialog}
        onSuccess={handleGetCustomerContractAdjustmentTemplate}
      />

      <TemplateFleetsDialog
        open={fleetDialogData.visible}
        data={fleetDialogData.data}
        onClose={handleCloseFleetDialog}
      />
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerContractAdjustmentTemplates,
      downloadContractAdjustmentTemplatePreview,
    },
    dispatch
  )
}

export default compose(
  withStyles((theme) => ({
    ...detailPageStyle(theme),
    ...fieldStyle(theme),
  })),
  connect((store) => {
    return {
      customerContractTemplate: store.customer.customerContractTemplate,
      customerContractTemplateLoading: store.customer.customerContractTemplateLoading,
    }
  }, mapDispatchToProps)
)(CustomerContractAdjustmentTemplates)
