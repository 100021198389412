import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomPaper = (props) => {
  const {classes, className, ...rest} = props

  return <Paper {...rest} className={cx(classes.flexGrow, classes.paperBackground, className)} />
}

export default withStyles(materialStyle)(CustomPaper)
