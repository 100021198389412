import endpoints from 'helper/endpoints'
import {fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_ADJUSTMENT_FAIL,
  GET_ADJUSTMENT_REQUEST,
  GET_ADJUSTMENT_SUCCESS,
  GET_CONTRACTS_FAIL,
  GET_CONTRACTS_REQUEST,
  GET_CONTRACTS_SUCCESS,
  GET_MARKS_FAIL,
  GET_MARKS_REQUEST,
  GET_MARKS_SUCCESS,
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_LIST_FAIL,
  GET_SUPPLIER_LIST_REQUEST,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_REQUEST,
  GET_SUPPLIER_SUCCESS,
  GET_SUP_AVAILA_FAIL,
  GET_SUP_AVAILA_REQUEST,
  GET_SUP_AVAILA_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getSupplierList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_SUPPLIER_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.suppliers + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_SUPPLIER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUPPLIER_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getSupplier = (id) => {
  return (dispatch) => {
    dispatch({type: GET_SUPPLIER_REQUEST})
    return httpClient
      .get(endpoints.suppliers + '/' + id)
      .then((res) => {
        dispatch({type: GET_SUPPLIER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUPPLIER_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createSupplier = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.suppliers, data)
      .then((res) => {
        fireSuccessToast(<Trans>New supplier created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchSupplier = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.suppliers + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Supplier updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getMarks = () => {
  return (dispatch) => {
    dispatch({type: GET_MARKS_REQUEST})
    return httpClient
      .get(endpoints.marks, {
        order_by: 'name:ASC',
      })
      .then((res) => {
        dispatch({type: GET_MARKS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_MARKS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getSupUnavailabilities = (id) => {
  return (dispatch) => {
    dispatch({type: GET_SUP_AVAILA_REQUEST})
    return httpClient
      .get(endpoints.suppliers + '/' + id + '/unavailabilities', {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_SUP_AVAILA_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUP_AVAILA_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const putSupUnavailabilities = (id, data) => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.suppliers + '/' + id + '/unavailabilities', data)
      .then((res) => {
        fireSuccessToast(<Trans>Unavailability dates updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getSupContracts = (id, isArchived, limit, offset) => {
  return (dispatch) => {
    dispatch({type: GET_CONTRACTS_REQUEST})
    return httpClient
      .get(`${endpoints.suppliers}/${id}/contracts`, {is_archived: isArchived, limit, offset})
      .then((res) => {
        dispatch({type: GET_CONTRACTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CONTRACTS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const postSupContracts = (id, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.suppliers}/${id}/contracts`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const downloadSupContract = (id, contractId) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.suppliers}/${id}/contracts/${contractId}/download`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadArchive = (id) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.suppliers}/${id}/contracts/download-archive`,
        {},
        {
          headers: {
            'Content-Type': 'application/zip',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const getSupContractAdjustments = (id, filter) => {
  return (dispatch) => {
    dispatch({type: GET_ADJUSTMENT_REQUEST})
    return httpClient
      .get(`${endpoints.suppliers}/${id}/contract-adjustments`, filter)
      .then((res) => {
        dispatch({type: GET_ADJUSTMENT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ADJUSTMENT_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadSupAdjustment = (id, adjustmentId) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.suppliers}/${id}/contract-adjustments/${adjustmentId}/download-signed-file`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadUnsignedSupAdjustment = (id, adjustmentId) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.suppliers}/${id}/contract-adjustments/${adjustmentId}/download-unsigned-file`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}
