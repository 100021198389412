import {useState} from 'react'

const useTableSort = (initialValue) => {
  const [tableSort, setTableSort] = useState(initialValue)

  const handleTableSort = (sort) => {
    setTableSort(sort)
  }

  return [tableSort, handleTableSort]
}

export default useTableSort
