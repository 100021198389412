import cx from 'classnames'
import AddFleetDialog from 'component/SuppliersPage/AddFleetDialog'
import EditFleetDialog from 'component/SuppliersPage/EditFleetDialog'
import FleetTermsDialog from 'component/SuppliersPage/FleetTermsDialog'
import detailPageStyle from 'component/detailPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import React, {useState} from 'react'
import {compose} from 'redux'
import {ReactComponent as Bin} from 'style/asset/bin.svg'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import {Add} from '@material-ui/icons'
import DescriptionIcon from '@material-ui/icons/Description'

const SupplierDetailFleets = (props) => {
  const {classes, submitting, supplierFleets, setSupplierFleets, supplierLoading} = props

  const [openFleetDialog, setOpenFleetDialog] = useState(false)
  const [openEditFleetDialog, setOpenEditFleetDialog] = useState({visible: false, row: null})
  const [openFleetTermsDialog, setOpenFleetTermsDialog] = useState({visible: false, row: null})

  const fleetColumns = [
    {
      name: 'id',
      label: <Trans>ID</Trans>,
    },
    {
      name: 'code',
      label: <Trans>Code</Trans>,
    },
    {
      name: 'customer',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'customer_cfs',
      label: <Trans>CFS</Trans>,
    },
    {
      name: 'customer_ic',
      label: <Trans>IC</Trans>,
    },
    {
      name: 'customer_dic',
      label: <Trans>DIC</Trans>,
    },
    {
      name: 'do_not_show_on_map_for_this_supplier',
      label: <Trans>Do not show on map</Trans>,
      render: (val) => (val ? <Trans>Yes</Trans> : <Trans>No</Trans>),
    },
    {
      name: 'id',
      label: '',
      align: 'right',
      width: 80,
      render: (val, row) =>
        row?.action !== 'create' && (
          <GridContainer wrap={'nowrap'} justifyContent={'space-between'} spacing={2}>
            <GridItem>
              <div
                onClick={handleOpenTermsDialog(row)}
                className={cx(classes.cursorPointer, classes.textCenter, classes.documentIcon)}
              >
                <DescriptionIcon />
              </div>
            </GridItem>
            <GridItem>
              <div
                onClick={handleOpenEditDialog(row)}
                className={cx(classes.cursorPointer, classes.textCenter)}
              >
                <Edit />
              </div>
            </GridItem>
            <GridItem>
              <div
                onClick={handleRemoveFleet(row)}
                className={cx(classes.cursorPointer, classes.textCenter)}
              >
                <Bin />
              </div>
            </GridItem>
          </GridContainer>
        ),
    },
  ]

  const handleRemoveFleet = (row) => () => {
    setSupplierFleets((prevState) => prevState.filter((fleet) => fleet.fleet?.id !== row.id))
  }

  const handleAddFleet = (values) => {
    setSupplierFleets((prevState) => [...prevState, ...values])
    handleCloseDialog()
  }

  const handleOpenDialog = () => {
    setOpenFleetDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenFleetDialog(false)
  }

  const handleOpenEditDialog = (row) => () => {
    setOpenEditFleetDialog({visible: true, row: row})
  }
  const handleCloseEditDialog = () => {
    setOpenEditFleetDialog({visible: false, row: null})
  }

  const handleOpenTermsDialog = (row) => () => {
    setOpenFleetTermsDialog({visible: true, row: row})
  }
  const handleCloseTermsDialog = () => {
    setOpenFleetTermsDialog({visible: false, row: null})
  }

  const handleEditFleet = (row) => (value) => {
    const supplierFleet = supplierFleets.filter((fleet) => fleet?.fleet?.id !== value?.fleet)
    const editedSupplierFleet = {
      do_not_show_on_map_for_this_supplier: value.do_not_show_on_map_for_this_supplier,
      fleet: {
        id: row.id,
        customer_cfs: row.customer_cfs,
        customer: row.customer,
        customer_code: row.customer_code,
      },
      action: 'update',
    }

    setSupplierFleets(() => [...supplierFleet, editedSupplierFleet])
    handleCloseEditDialog()
  }

  return (
    <>
      <GridItem container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
        <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
          <GridContainer justifyContent={'space-between'} alignItems={'center'}>
            <GridItem xs={6}>
              <Trans>Fleets</Trans>
            </GridItem>
            <GridItem container xs={6} justifyContent={'flex-end'}>
              <PrimaryButton
                fullWidth={false}
                disabled={submitting}
                size="small"
                textPadding={5}
                onClick={handleOpenDialog}
                className={classes.addButton}
                text={<Add fontSize={'small'} />}
              />
            </GridItem>
          </GridContainer>
        </BoxFullWidth>

        <GridItem container xs={12}>
          <Table
            data={supplierFleets
              ?.map((fleet) => ({
                action: fleet.action,
                do_not_show_on_map_for_this_supplier: fleet.do_not_show_on_map_for_this_supplier,
                ...fleet.fleet,
              }))
              .sort((a, b) => a.id - b.id)}
            loading={supplierLoading}
            columns={fleetColumns}
            pagination={false}
            className={cx(classes.lastRowRoundBorder, classes.detailTable)}
          />
        </GridItem>
      </GridItem>

      <AddFleetDialog
        onClose={handleCloseDialog}
        open={openFleetDialog}
        onSubmit={handleAddFleet}
        supplierFleets={supplierFleets}
      />

      <EditFleetDialog
        onClose={handleCloseEditDialog}
        open={openEditFleetDialog.visible}
        row={openEditFleetDialog.row}
        onSubmit={handleEditFleet}
      />

      <FleetTermsDialog
        onClose={handleCloseTermsDialog}
        open={openFleetTermsDialog.visible}
        row={openFleetTermsDialog.row}
      />
    </>
  )
}

export default compose(withStyles(detailPageStyle))(SupplierDetailFleets)
