const materialStyle = (theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  paperBackground: {
    backgroundColor: theme.palette.background.white,
  },

  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },

  fullHeight: {
    height: '100%',
  },

  tableClass: {
    minWidth: '100%',
    borderCollapse: 'separate',
  },

  tableHeadCellClass: {
    color: theme.palette.text.primary + 'cc',
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default + ' !important',
    fontSize: '12px !important',
    height: 40,
    borderTop: '1px solid ' + theme.palette.border,
    borderBottom: '1px solid ' + theme.palette.border,
    borderRight: 'none !important',

    '&:first-child': {
      borderLeft: '1px solid ' + theme.palette.border + ' !important',
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
      borderRight: '1px solid ' + theme.palette.border + ' !important',
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },

  // remove bottom line under last row in table
  tableRow: {
    '&:last-child': {
      '& .MuiTableCell-body': {
        borderBottom: 'none',
      },
    },
  },

  tableCellClass: {
    padding: theme.spacing(2),
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    lineHeight: 1,
    height: 40,
    fontSize: 13,
    backgroundColor: theme.palette.background.elitGray,

    '& .MuiTableSortLabel-root': {
      '&:hover': {
        color: theme.palette.text.primary + 'ff',
      },
    },

    '&:first-child': {
      borderLeft: '1px solid ' + theme.palette.border,
    },
    '&:last-child': {
      borderRight: '1px solid ' + theme.palette.border,
    },
  },

  tableLabel: {
    cursor: 'default',
  },

  tableLoadingRow: {
    backgroundColor: theme.palette.background.elitGray,
    '& td': {
      borderBottom: '1px solid ' + theme.palette.border,
      borderLeft: '1px solid ' + theme.palette.border,
      borderRight: '1px solid ' + theme.palette.border,
    },
  },

  paginationCell: {
    padding: 0,
  },
  emptyTable: {
    borderBottom: '1px solid ' + theme.palette.border,
    borderLeft: '1px solid ' + theme.palette.border,
    borderRight: '1px solid ' + theme.palette.border,
    height: 40,
    maxHeight: 40,
    boxSizing: 'border-box',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.text.hint,
    '& tr:last-of-type': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  emptyTableText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tablePaginationContainer: {
    height: 50,
    boxSizing: 'border-box',
    width: '100%',
    borderBottom: '1px solid ' + theme.palette.border,
    borderLeft: '1px solid ' + theme.palette.border,
    borderRight: '1px solid ' + theme.palette.border,
    display: 'flex',
    flexDirection: 'row',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.elitGray,
  },

  arrowBoxLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    borderRight: '1px solid ' + theme.palette.lightBorder,
    cursor: 'pointer',

    '& svg': {
      transform: 'rotate(180deg)',
    },

    '&:hover': {
      '& svg': {
        fill: '#000000',
      },
    },
  },

  arrowBoxRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    borderLeft: '1px solid ' + theme.palette.lightBorder,
    cursor: 'pointer',

    '&:hover': {
      '& svg': {
        transition: ' fill 200ms',
        fill: '#000000',
      },
    },
  },

  pageInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 12,
    padding: '0 0 0 30px',
    width: 220,
    boxSizing: 'border-box',
    justifyContent: 'center',
  },

  pageInfoPages: {},
  pageInfoTotalCount: {},

  pages: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  page: {
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
    width: 50,
    borderLeft: '1px solid ' + theme.palette.lightBorder,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      color: theme.palette.error.main,
    },

    '&:last-child': {
      borderRight: '1px solid ' + theme.palette.lightBorder,
    },
  },

  pageActive: {
    color: theme.palette.error.main,
  },

  pageSetting: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 30px 0 0',
    width: 220,
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
  },

  pageSettingText: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  pageSettingOptions: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },

  pageSettingsOption: {
    fontWeight: 700,
    cursor: 'pointer',
    color: theme.palette.text.primary,
    paddingLeft: 10,

    '&:hover': {
      color: theme.palette.error.main,
    },
    '&:first-child': {
      paddingLeft: 20,
    },
  },

  pageSettingOptionSelected: {
    color: theme.palette.error.main,
  },

  // dialog window styling
  dialogTitle: {
    padding: '10px 40px',
    fontSize: 20,
    fontWeight: 600,
    minHeight: 40,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    padding: '16px',
  },
  dialogActions: {
    padding: theme.spacing(4),
  },
  dialogCloseButton: {
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    padding: 16,
    height: 44,
    width: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '& svg': {
      width: 16,
      height: 16,
    },
  },

  iconButton: {
    zIndex: 1,
    width: 38,
    height: 38,
    border: '1px solid ' + theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    background: theme.palette.background.paper,
    webkitBoxShadow: '0px 0px 10px -5px rgba(0,0,0,0.65)',
    mozBoxShadow: '0px 0px 10px -5px rgba(0,0,0,0.65)',
    boxShadow: '0px 0px 10px -5px rgba(0,0,0,0.65)',
    '& svg': {
      transition: 'opacity 100ms',
      opacity: 0.5,
    },

    '&:hover': {
      transition: 'border 100ms, background 100ms',
      border: '1px solid ' + theme.palette.secondary.dark,
      background: theme.palette.background.default,

      '& svg': {
        transition: 'opacity 100ms',
        opacity: 1,
      },
    },
  },

  loader: {
    border: '5px solid transparent',
    borderRadius: '50%',
    borderTop: '5px solid ' + theme.palette.primary.main + '90',
    width: 60,
    height: 60,
    animation: '$spin 1.2s linear infinite',
    margin: '0 auto',
    padding: 10,
  },

  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
  },

  loaderWrapper: {
    minHeight: '25vh',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
  },

  buttonLoader: {
    borderTop: '4px solid ' + theme.palette.background.white + 'cc',
    width: 10,
    height: 10,
  },

  // icon in warning / confirmation dialog
  warningDialogTitleIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  warningDialogTitle: {
    color: theme.palette.primary.main,
    fontSize: 26,
  },
  warningDialogIcon: {
    fill: theme.palette.warning.main,
    height: 50,
    width: 50,
    paddingRight: theme.spacing(3),
  },

  // primary button padding adjusted to design
  primaryButtonPadding: {
    boxSizing: 'border-box',
    minHeight: 50,
    padding: '8px 14px',
    transition: 'opacity 200ms',
    '&:hover': {
      transition: 'opacity 200ms',
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main,
    },
    '&.MuiButton-outlined': {
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  },

  formSubmitButton: {
    height: '30px !important',
    boxSizing: 'border-box',
    fontSize: 12,
    textTransform: 'capitalize',
  },

  buttonBottomMargin: {
    marginBottom: '23px',
  },

  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },

  cellClass: {
    padding: '4px 10px',
    whiteSpace: 'nowrap',
    minWidth: 96,
  },
})

export default materialStyle
