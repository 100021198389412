import {
  GET_REGION_FAIL,
  GET_REGION_LIST_FAIL,
  GET_REGION_LIST_REQUEST,
  GET_REGION_LIST_SUCCESS,
  GET_REGION_REQUEST,
  GET_REGION_SUCCESS,
} from 'redux/actionType'

const initState = {
  regionList: [],
  regionListLoading: false,
  regionListMeta: {},
  region: {},
  regionLoading: false,
}

const regionReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_REGION_LIST_REQUEST: {
      return {
        ...state,
        regionListLoading: true,
      }
    }
    case GET_REGION_LIST_SUCCESS: {
      return {
        ...state,
        regionListLoading: false,
        regionList: action.data?.objects,
        regionListMeta: action.data?.meta,
      }
    }
    case GET_REGION_LIST_FAIL: {
      return {
        ...state,
        regionListLoading: false,
      }
    }
    case GET_REGION_REQUEST: {
      return {
        ...state,
        region: {},
        regionLoading: true,
      }
    }
    case GET_REGION_SUCCESS: {
      return {
        ...state,
        region: action.data,
        regionLoading: false,
      }
    }
    case GET_REGION_FAIL: {
      return {
        ...state,
        regionLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default regionReducer
