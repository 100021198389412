import {
  GET_CUSTOMER_CONTRACT_TEMPLATE_FAIL,
  GET_CUSTOMER_CONTRACT_TEMPLATE_REQUEST,
  GET_CUSTOMER_CONTRACT_TEMPLATE_SUCCESS,
  GET_CUSTOMER_DEFAULT_TEMPLATE_FAIL,
  GET_CUSTOMER_DEFAULT_TEMPLATE_REQUEST,
  GET_CUSTOMER_DEFAULT_TEMPLATE_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_LIST_REQUEST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
} from 'redux/actionType'

const initState = {
  customerList: [],
  customerListLoading: false,
  customerListMeta: {},
  customer: {},
  customerLoading: false,
  customerContractTemplate: [],
  customerContractTemplateMeta: {},
  customerContractTemplateLoading: false,
  customerDefaultTemplate: [],
  customerDefaultTemplateLoading: false,
}

const customerReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST_REQUEST: {
      return {
        ...state,
        customerListLoading: true,
      }
    }
    case GET_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        customerListLoading: false,
        customerList: action.data?.objects,
        customerListMeta: action.data?.meta,
      }
    }
    case GET_CUSTOMER_LIST_FAIL: {
      return {
        ...state,
        customerListLoading: false,
      }
    }
    case GET_CUSTOMER_REQUEST: {
      return {
        ...state,
        customer: {},
        customerLoading: true,
      }
    }
    case GET_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customer: action.data,
        customerLoading: false,
      }
    }
    case GET_CUSTOMER_FAIL: {
      return {
        ...state,
        customerLoading: false,
      }
    }
    case GET_CUSTOMER_CONTRACT_TEMPLATE_REQUEST: {
      return {
        ...state,
        customerContractTemplateLoading: true,
      }
    }
    case GET_CUSTOMER_CONTRACT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        customerContractTemplate: action.data.objects,
        customerContractTemplateMeta: action.data.meta,
        customerContractTemplateLoading: false,
      }
    }
    case GET_CUSTOMER_CONTRACT_TEMPLATE_FAIL: {
      return {
        ...state,
        customerContractTemplateLoading: false,
      }
    }
    case GET_CUSTOMER_DEFAULT_TEMPLATE_REQUEST: {
      return {
        ...state,
        customerDefaultTemplateLoading: true,
      }
    }
    case GET_CUSTOMER_DEFAULT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        customerDefaultTemplate: action.data,
        customerDefaultTemplateLoading: false,
      }
    }
    case GET_CUSTOMER_DEFAULT_TEMPLATE_FAIL: {
      return {
        ...state,
        customerDefaultTemplateLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default customerReducer
