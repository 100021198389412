import {getServiceUrl} from 'helper/functions'

// map of endpoints for cleaner use in api calls
const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  currentUser: `${getServiceUrl('auth')}/current-user`,
  user: `${getServiceUrl('auth')}/users`,
  role: `${getServiceUrl('auth')}/roles`,

  // invoices
  invoices: `${getServiceUrl('cfs')}/invoices`,
  invoicesBulkExportable: `${getServiceUrl('cfs')}/invoices/bulk-exportable`,
  invoiceStatuses: `${getServiceUrl('cfs')}/invoice-statuses`,

  exportBulkInvoices: `${getServiceUrl('cfs')}/export-bulk-invoices`,
  exportInvoices: `${getServiceUrl('cfs')}/export-invoices`,

  bulkImport: `${getServiceUrl('cfs')}/csv-imports`,

  customers: `${getServiceUrl('cfs')}/customers`,
  customersHasBulkExportableInvoices: `${getServiceUrl(
    'cfs'
  )}/customers/with-bulk-exportable-invoices`,

  providers: `${getServiceUrl('cfs')}/providers`,
  fleets: `${getServiceUrl('cfs')}/fleets`,
  accounts: `${getServiceUrl('cfs')}/accounts`,
  branches: `${getServiceUrl('cfs')}/branches`,
  terms: `${getServiceUrl('cfs')}/terms`,
  predefinedTerms: `${getServiceUrl('cfs')}/predefined-terms`,
  typesOfWork: `${getServiceUrl('cfs')}/types-of-work`,
  areasOfWork: `${getServiceUrl('cfs')}/areas-of-work`,
  units: `${getServiceUrl('cfs')}/units`,
  regions: `${getServiceUrl('cfs')}/regions`,
  suppliers: `${getServiceUrl('cfs')}/suppliers`,
  marks: `${getServiceUrl('cfs')}/marks`,
  cars: `${getServiceUrl('cfs')}/cars`,
  attachmentTypes: `${getServiceUrl('cfs')}/attachment-types`,
  companies: `${getServiceUrl('cfs')}/companies`,
  statuses: `${getServiceUrl('cfs')}/statuses`,
  countries: `${getServiceUrl('cfs')}/countries`,
}

export default endpoints
