import detailPageStyle from 'component/detailPageStyle'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getFleetList} from 'redux/action/fleetsAction'
import {
  createPredefinedTerm,
  getPredefinedTerm,
  patchPredefinedTerm,
} from 'redux/action/predefinedTermsAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const PredefinedTermDetail = (props) => {
  const {
    classes,
    getFleetList,
    fleetList,
    fleetListLoading,
    getPredefinedTerm,
    predefinedTerm,
    predefinedTermLoading,
    createPredefinedTerm,
    patchPredefinedTerm,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createPredefinedTerm(preparedValues)
        .then((res) => {
          redirectTo('/predefined-term')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchPredefinedTerm(id, preparedValues)
        .then((res) => {
          redirectTo('/predefined-term')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...predefinedTerm,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getPredefinedTerm(id).catch(() => {})
      }
      getFleetList().catch(() => {})
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form onSubmit={handleSubmit} initialValues={id !== 'new' ? getInitialValues() : {}}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="description"
                      label={<Trans>Description</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="price"
                      label={<Trans>Price</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="fleet_id"
                      label={<Trans>Fleet</Trans>}
                      component={SelectInput}
                      options={fleetList?.map((fleet) => ({
                        name: fleet.customer_code ? fleet.customer_code : fleet.customer,
                        value: fleet.id,
                      }))}
                      loading={fleetListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

PredefinedTermDetail.propTypes = {
  classes: PropTypes.object,
  getFleetList: PropTypes.func,
  fleetList: PropTypes.array,
  fleetListLoading: PropTypes.bool,
  getPredefinedTerm: PropTypes.func,
  predefinedTerm: PropTypes.object,
  predefinedTermLoading: PropTypes.bool,
  createPredefinedTerm: PropTypes.func,
  patchPredefinedTerm: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFleetList,
      getPredefinedTerm,
      createPredefinedTerm,
      patchPredefinedTerm,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      fleetList: store.fleet.fleetList,
      fleetListLoading: store.fleet.fleetListLoading,
      predefinedTerm: store.predefinedTerm.predefinedTerm,
      predefinedTermLoading: store.predefinedTerm.predefinedTermLoading,
    }
  }, mapDispatchToProps)
)(PredefinedTermDetail)
